import axios from "axios";

class SingleCancelRequest {
  // This object allows the creation of a request, when there is a new
  // on then the previous one is cancelled.  This stops multiple in-flight
  // requests for the same URL resource.
  constructor() {
    this.current_source = axios.CancelToken.source();
    this.request = function request(axios_request, url, data, handleJson, handleError) {
      // If there is already a request in flight, replace it by cancelling
      // it which will cause the catch block below to be executed for that
      // instance of the request.
      if (this.current_source) {
        this.current_source.cancel("Operation replaced");
      }
      // Create a cancel token for this new request.
      this.current_source = axios.CancelToken.source();

      const request_promise = data
        ? axios_request(url, data, { cancelToken: this.current_source.token })
        : axios_request(url, { cancelToken: this.current_source.token });

      return request_promise
        .then(response => response.json())
        .then(handleJson)
        .catch(error => {
          if (axios.isCancel(error)) {
            // We are going to go down into the main handler code
            // This just means we silence the exceptions.
            return Promise.resolve();
          }
        })
        .catch(handleError)
    };

    this.cancel = function cancel() {
      if (this.current_source) {
        this.current_source.cancel("Operation replaced");
      }
    };

    this.get = function get(url, handle_json, handle_error = null) {
      return this.request(axios.get, url, null, handle_json, handle_error);
    };

    this.post = function post(url, data, handle_json, handle_error = null) {
      return this.request(axios.post, url, data, handle_json, handle_error);
    };
  }
}

export default {
  SingleCancelRequest,
}
