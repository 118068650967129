<template>
  <div
    v-if="showLoadingBar"
    class="loading-bar"
    :style="{left: sidebarVisible ? 230 + 'px' : 0}"
  />
</template>

<script>
import { useThrottleFn } from '@vueuse/core'

const roundPrecision = 100000

export default {
  data() {
    return {
      showLoadingBar: false,
      displayedPercentagedLoaded: 0,
    }
  },
  computed: {
    percentageLoaded() {
      if (!this.$store.state.display.loadingJobs.length) return 100;
      const currentJobProgresses = this.$store.state.display.loadingJobs.map(job => job.completedIncrements / job.totalIncrements);
      return 100 * currentJobProgresses.reduce((partialSum, a) => partialSum + a, 0) / currentJobProgresses.length;
    },
    sidebarVisible() {
      return + this.$store.state.display.sidebar;
    },
  },
  watch: {
    percentageLoaded(value) {
      this.updateLoadingBar(value)
    },
  },
  methods: {
    updateLoadingBar: useThrottleFn(function(value) {
      this.displayedPercentagedLoaded = Math.round(value * roundPrecision) / roundPrecision
      this.showLoadingBar = this.displayedPercentagedLoaded < 100
    }, 500, {trailing:true}),
  },
}
</script>

<style scoped>
.loading-bar {
    top: 0;
    position: fixed;
    background-color: var(--secondary-colour);
    width: calc(0.5em - (230px*v-bind(sidebarVisible)) + 1% * (v-bind(displayedPercentagedLoaded)));
    margin-left: -0.5em;
    height: 5px;
    z-index: 100;
    -webkit-transition: width 0.2s ease-in-out;
    -moz-transition: width 0.2s ease-in-out;
    -o-transition: width 0.2s ease-in-out;
    transition: width 0.2s ease-in-out;
}
</style>
