<template>
  <header class="header-mobile">
    <router-link :to="{ name: 'Dashboard Home' }">
      <Logo class="mobile-logo" />
    </router-link>
    <span class="menu-toggle">
      <font-awesome-icon
        v-if="user_authenticated"
        :icon="['far', 'bars']"
        @click="toggleSidebar();"
      />
    </span>
  </header>
</template>

<script>
import "bootstrap/dist/js/bootstrap";
import UserStateMixin from "../shared/mixins/UserStateMixin";
import Logo from "@/components/shared/Logo";

export default {
  components: {
    UserStateMixin,
    Logo,
  },
  mixins: [UserStateMixin],
  methods: {
    toggleSidebar() {
      this.$emit("togglesidebar");
    },
  },
};
</script>
