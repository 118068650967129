/*
 Short lived task data that will be reset when the user refreshes.
 - The expectation is that this data will be used and then discarded.
 - Used to track things like if the page needs to refresh data.
*/

/**
 * Default State
 */
const defaultState = () => ({
  dataNeedsRefreshing: false,
});

/**
 * Mutations
 */
const ephermalTasksMutations = {
  RESET_STATE(state) {
    Object.assign(state, defaultState());
  },
  SET_DATA_NEEDS_REFRESHING(state, value) {
    state.dataNeedsRefreshing = value;
  },
};

/**
 * Actions
 */
const ephermalTasksActions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },
  setDataNeedsRefreshing({ commit }, value) {
    commit("SET_DATA_NEEDS_REFRESHING", value);
  },
};

/**
 * Getters
 */
const ephermalTasksGetters = {
  doesDataNeedRefreshing: state => state.dataNeedsRefreshing,
};

/**
 * Setup
 */
const StoreEphermalModule = {
  namespaced: true,
  state: defaultState(),
  mutations: ephermalTasksMutations,
  actions: ephermalTasksActions,
  getters: ephermalTasksGetters,
};

export default StoreEphermalModule;
