const selections_state = {
  year: null,
  season: null,
  crop: null,
  variety: null,
  field: null,
  v_group: null,
  group_field: null,
  admin_group_field:{
    id: null,
    uuid: null,
    name: null,
    type: null,
  },
};

const selections_getters = {};

const selections_mutations = {

  SET_SEASON(state, season) {
    state.season = season;
  },

  SET_ADMIN_GROUP_FIELD(state, admin_group_field){
    state.admin_group_field = admin_group_field
  },

  SET_V_GROUP(state, v_group) {
    state.v_group = v_group;
  },

  SET_GROUP_FIELD(state, group_field) {
    state.group_field = group_field;
  },

  SET_YEAR(state, year) {
    if (!(year && 'id' in year && 'name' in year)) throw `Cannot set year to ${year}`;
    state.year = {id: Number(year.id), name: String(year.name)}
  },

  /*
    Sets the year from a single value.
  */
  SET_YEAR_FROM_URL(state, year) {
    if (!year) return;

    state.year = {
      id: Number(year),
      name: String(year),
    }
  },

  SET_CROP(state, crop) {
    state.crop = crop;
  },

  SET_VARIETY(state, variety) {
    state.variety = variety;
  },

  SET_FIELD(state, field) {
    state.field = field;
  },

};

const selections_actions = {
  requestToSetYear({ commit }, year) {
    commit("SET_YEAR_FROM_URL", year);
  },
  requestToSetCrop({ commit }, crop) {
    commit("SET_CROP", crop);
  },
  requestToSetVariety({ commit }, variety) {
    commit("SET_VARIETY", variety);
  },
  requestToSetField({ commit }, field) {
    commit("SET_FIELD", field);
  },
};

const selections_module = {
  namespaced: true,
  state: selections_state,
  getters: selections_getters,
  mutations: selections_mutations,
  actions: selections_actions,
};

export default selections_module;
