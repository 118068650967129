import NetworkModule from "../../lib/NetworkModule";

class AbstractAuthBackend {
  constructor() {
    this.network_request = new NetworkModule.SingleCancelRequest();
  }

  login(error_callback, success_callback, creds) {
    throw new Error('You have to implement the method doSomething!');
  }

  logout(redirect_url) {
    throw new Error('You have to implement the method doSomething!');
  }

  refresh(logout_func = null) {
    throw new Error('You have to implement the method doSomething!');
  }
}

export default AbstractAuthBackend;
