import { i18n } from "@/plugins/i18n";
import FeatureModule from "../../../lib/FeatureModule";
import functions from "../functions";

const FEATURE_TYPE = FeatureModule.FEATURE_TYPE;

const sidebarLinks = {
  hasRequiredGlobalFeatures(store, features) {
    for (let i = 0; i < features.length; i++) {
      let feature = features[i];
      if (!store.getters[`settings/${feature}`]) {
        return false;
      }
      // if (feature.startsWith("!")) {
      //
      // }
    }
    return true;
  },

  reduceLinks(links, store) {
    let newLinks = [];
    links.forEach((linkSection) => {
      for (const [sectionTitle, sectionLinks] of Object.entries(linkSection)) {
        let newSectionLinks = [];
        sectionLinks.forEach((sectionLink) => {
          // Check if has required global feature
          if (
            !sectionLink.requireGlobalFeatures ||
            this.hasRequiredGlobalFeatures(
              store,
              sectionLink.requireGlobalFeatures,
            )
          ) {
            // Check that the group feature is either 'enabled' or 'lockscreen'
            let baseLink = {
              label: sectionLink.label,
              id: sectionLink.id,
              badge: sectionLink.badge,
              icon: sectionLink.icon,
              newUntil: sectionLink?.newUntil,
              updatedUntil: sectionLink?.updatedUntil,
            };
            if (
              !sectionLink.feature ||
              functions.groupHasFeature(
                sectionLink.feature,
                store.state.user.group.features,
                "enabled",
              )
            ) {
              baseLink["url"] = sectionLink.url;
              baseLink["isLocked"] = false;
              newSectionLinks.push(baseLink);
            } else if (
              functions.groupHasFeature(
                sectionLink.feature,
                store.state.user.group.features,
                "lockscreen",
              )
            ) {
              // TODO create generic lockscreen
              let lockScreen = "/feature/marketplace"; // No lockscreen defined, so fall back to one of the others
              if (sectionLink.lockScreen) {
                lockScreen = sectionLink.lockScreen;
              }
              baseLink["url"] = lockScreen;
              baseLink["isLocked"] = true;
              newSectionLinks.push(baseLink);
            }
          }
        });
        if (newSectionLinks.length > 0) {
          let section = {};
          section[sectionTitle] = newSectionLinks;
          newLinks.push(section);
        }
      }
    });
    return newLinks;
  },

  farmLinks(_, store) {
    let links = [];
    try {
      links.push({
        marketplace: [
          {
            label: "Request Products",
            url: "/farm/marketplace/request/products",
            lockScreen: "/feature/marketplace",
            id: "sidebar-request-products",
            requireGlobalFeatures: ["marketplace"],
            feature: FEATURE_TYPE.MARKETPLACE,
          },
          {
            label: "Requests",
            url: "/farm/marketplace/requests",
            lockScreen: "/feature/marketplace",
            badge: "open_requests",
            id: "sidebar-requests",
            requireGlobalFeatures: ["marketplace"],
            feature: FEATURE_TYPE.MARKETPLACE,
          },
          {
            label: "Quotes",
            url: "/farm/marketplace/requests/quotes",
            lockScreen: "/feature/marketplace",
            badge: "quoted_requests",
            id: "sidebar-quotes",
            requireGlobalFeatures: ["marketplace"],
            feature: FEATURE_TYPE.MARKETPLACE,
          },
          {
            label: "Order History",
            url: "/farm/marketplace/transactions",
            lockScreen: "/feature/marketplace",
            id: "sidebar-quote-history",
            requireGlobalFeatures: ["marketplace"],
            feature: FEATURE_TYPE.MARKETPLACE,
          },
          {
            label: i18n.t("productCatalogue.title"),
            url: "/farm/products/view",
            lockScreen: "/feature/marketplace",
            id: "sidebar-product_history",
            requireGlobalFeatures: ["marketplace"],
            feature: FEATURE_TYPE.MARKETPLACE,
          },
          {
            label: "Suppliers",
            url: "/farm/marketplace/groups/suppliers",
            lockScreen: "/feature/marketplace",
            id: "sidebar-suppliers",
            requireGlobalFeatures: ["marketplace"],
            feature: FEATURE_TYPE.MARKETPLACE,
          },
        ],
      });

      if (
        functions.groupHasFeature(
          FEATURE_TYPE.EXPLORER,
          store.state.user.group.features,
          "enabled",
        ) ||
        functions.groupHasFeature(
          FEATURE_TYPE.EXPLORER,
          store.state.user.group.features,
          "lockscreen",
        )
      ) {
        let analyticsLinks = [];
        analyticsLinks.push({
          id: "sidebar-farm-explorer",
          label: "Explorer",
          url: "/farm/analytics/explorer",
          lockScreen: "/feature/explorer",
          feature: FEATURE_TYPE.EXPLORER,
          requireGlobalFeatures: ["explorer"],
        });

        analyticsLinks.push({
          id: "sidebar-programmecheck",
          label: "Programmes",
          url: "/farm/programme",
          lockScreen: "/feature/programmecheck",
          feature: {
            operator: "and",
            features: [FEATURE_TYPE.PROGRAMMECHECK, FEATURE_TYPE.EXPLORER],
          },
          requireGlobalFeatures: ["program_check"],
        });

        analyticsLinks.push({
          id: "sidebar-field-by-field",
          label: "Field by field",
          url: "/farm/analytics/field-by-field",
          lockScreen: "/feature/field-by-field",
          feature: {
            operator: "and",
            features: [FEATURE_TYPE.FIELDSANALYTICS, FEATURE_TYPE.EXPLORER],
          },
        });

        analyticsLinks.push(
          {
            id: "sidebar-myprices",
            label: "Prices",
            url: "/farm/analytics/myprices",
            lockScreen: "/feature/pricecheck",
            requireGlobalFeatures: ["price_check"],
            feature: FEATURE_TYPE.FARMBENCH,
          },
          {
            label: "Purchasing",
            id: "sidebar-purchasing",
            url: "/farm/analytics/pricecheck",
            lockScreen: "/feature/pricecheck",
            requireGlobalFeatures: ["price_check"],
            feature: {
              operator: "or",
              features: [FEATURE_TYPE.PRICECHECKLITE, FEATURE_TYPE.FARMBENCH],
            },
          },
        );

        analyticsLinks.push({
          label: "Crop P&L",
          id: "sidebar-crop-profit-loss",
          url: "/farm/analytics/profit-loss",
          lockScreen: "/feature/explorer",
          feature: {
            operator: "and",
            features: [FEATURE_TYPE.EXPLORER, FEATURE_TYPE.LABOUR_MACHINERY],
          },
        });

        analyticsLinks.push({
          id: "sidebar-virtual-groups",
          label: "Virtual Groups",
          url: "/farm/analytics/virtual-groups",
          badge: "virtualgroup_invites",
          lockScreen: "/feature/pricecheck",
          feature: {
            operator: "or",
            features: [FEATURE_TYPE.EXPLORER, FEATURE_TYPE.FARMBENCH],
          },
        });

        analyticsLinks.push({
          id: "sidebar-analytics-reports",
          label: "Reports",
          url: "/farm/analytics/reports",
          requireGlobalFeatures: ["explorer"],
          feature: {
            operator: "and",
            features: [FEATURE_TYPE.EXPLORER, FEATURE_TYPE.ANALYTICS_REPORTS],
          },
          updatedUntil: new Date(2024, 11, 31), // 31st Dec 2024
        });

        links.push({ analytics: analyticsLinks });
      } else {
        let priceCheckLinks = [];
        priceCheckLinks.push(
          {
            label: "Overview",
            id: "sidebar-pricecheck",
            url: "/farm/pricecheck",
            lockScreen: "/feature/pricecheck",
            feature: {
              operator: "or",
              features: [FEATURE_TYPE.PRICECHECKLITE, FEATURE_TYPE.FARMBENCH],
            },
          },
          {
            label: "My Prices",
            id: "sidebar-myprices",
            url: "/farm/pricecheck/myprices",
            lockScreen: "/feature/pricecheck",
            feature: FEATURE_TYPE.FARMBENCH,
          },
          {
            label: "My Purchases",
            id: "sidebar-mypurchases",
            url: "/farm/pricecheck/mypurchases",
            lockScreen: "/feature/pricecheck",
            feature: FEATURE_TYPE.FARMBENCH,
          },
          {
            label: "Product Catalogue",
            id: "sidebar-products",
            url: "/farm/products/view",
            lockScreen: "/feature/pricecheck",
            feature: FEATURE_TYPE.FARMBENCH,
          },
        );
        links.push({ "price check": priceCheckLinks });
        let programmeCheckLinks = [];
        programmeCheckLinks.push({
          label: "Overview",
          id: "sidebar-programmecheck",
          url: "/farm/programmecheck",
          lockScreen: "/feature/programmecheck",
          feature: FEATURE_TYPE.PROGRAMMECHECK,
        });
        programmeCheckLinks.push({
          label: "Programmes",
          url: "/farm/programme",
          id: "sidebar-programme",
          lockScreen: "/feature/programmecheck",
          feature: FEATURE_TYPE.PROGRAMMECHECK,
        });
        programmeCheckLinks.push({
          label: "Activities",
          url: "/farm/activities",
          id: "sidebar-activities",
          lockScreen: "/feature/programmecheck",
          feature: FEATURE_TYPE.PROGRAMMECHECK,
        });
        links.push({ "programme check": programmeCheckLinks });

        links.push({
          analytics: [
            {
              label: "Analytics",
              url: "/farm/analytics/explorer",
              id: "sidebar-explorer",
              lockScreen: "/feature/analytics",
              requireGlobalFeatures: ["explorer"],
              feature: FEATURE_TYPE.EXPLORER,
            },
          ],
        });
      }

      let trackerLinks = [
        {
          label: "Crop Progress",
          id: "sidebar-business-tracker-crops",
          url: "/farm/tracker/business-tracker",
          lockScreen: "/feature/tracker",
          requireGlobalFeatures: ["business_tracker"],
          feature: FEATURE_TYPE.BUSINESSTRACKER,
        },
      ];

      trackerLinks.push({
        label: "Budgets",
        url: "/farm/tracker/budgets",
        id: "sidebar-budgets",
        lockScreen: "/feature/tracker",
        requireGlobalFeatures: ["business_tracker"],
        feature: FEATURE_TYPE.BUSINESSTRACKER,
      });

      trackerLinks.push(
        {
          label: i18n.t("marketwatch.menu.commodities"),
          id: "sidebar-tracker-commodities",
          url: "/farm/tracker/commodities",
          requireGlobalFeatures: ["business_tracker"],
          feature: FEATURE_TYPE.BUSINESSTRACKER,
        },
        {
          label: i18n.t("marketwatch.menu.inputs"),
          id: "sidebar-tracker-inputs",
          url: "/farm/tracker/inputs",
          requireGlobalFeatures: ["business_tracker"],
          feature: FEATURE_TYPE.BUSINESSTRACKER,
        },
        {
          label: i18n.t("products.productList"),
          id: "sidebar-tracker-catalogue",
          url: "/farm/products/view",
          requireGlobalFeatures: ["business_tracker"],
          feature: FEATURE_TYPE.BUSINESSTRACKER,
        },
        {
          label: i18n.t("marketwatch.menu.alerts"),
          id: "sidebar-tracker-alerts",
          url: "/farm/tracker/alerts",
          requireGlobalFeatures: ["business_tracker"],
          feature: FEATURE_TYPE.BUSINESSTRACKER,
        },
      );

      links.push({
        tracker: trackerLinks,
      });

      let dataLinks = [];
      dataLinks.push({
        id: "sidebar-data-upload",
        label: "Uploads",
        url: "/farm/data/upload",
        lockScreen: "/feature/explorer",
        feature: FEATURE_TYPE.DATA_UPLOAD,
      });
      dataLinks.push({
        id: "sidebar-data-surveys",
        label: "Surveys",
        url: "/farm/data/surveys",
        feature: FEATURE_TYPE.SURVEYS,
      });
      dataLinks.push({
        id: "sidebar-data-supply-chain",
        label: "Supply Contracts",
        url: "/farm/data/supply/contracts",
        feature: FEATURE_TYPE.SURVEYS,
      }),
      dataLinks.push({
        id: "sidebar-data-purchases",
        label: "Purchases",
        url: "/farm/data/mypurchases",
        lockScreen: "/feature/pricecheck",
        feature: FEATURE_TYPE.FARMBENCH,
      });
      dataLinks.push(
        {
          id: "sidebar-data-applications",
          label: "Applications",
          url: "/farm/data/activities",
          lockScreen: "/feature/programmecheck",
          feature: FEATURE_TYPE.PROGRAMMECHECK,
        },
        {
          id: "sidebar-data-yields",
          label: "Yields",
          url: "/farm/data/yields",
          lockScreen: "/feature/explorer",
          feature: FEATURE_TYPE.EXPLORER,
        },
        {
          label: "Contracts",
          id: "sidebar-data-contracts",
          url: "/farm/data/contracts",
          lockScreen: "/feature/tracker",
          feature: FEATURE_TYPE.BUSINESSTRACKER,
        },
        {
          label: "Sales",
          id: "sidebar-data-sales",
          url: "/farm/data/sales",
          lockScreen: "/feature/tracker",
          feature: FEATURE_TYPE.BUSINESSTRACKER,
        },
      );

      dataLinks.push({
        label: "Field Operations",
        id: "sidebar-data-field-ops",
        url: "/farm/field-operations",
        lockScreen: "/feature/explorer",
        feature: FEATURE_TYPE.LABOUR_MACHINERY,
      });

      dataLinks.push({
        id: "sidebar-data-insights",
        label: "Insights",
        url: "/farm/insights",
        feature: FEATURE_TYPE.INSIGHTS,
      });

      links.push({ Data: dataLinks });

      // marketwatch
      if (
        store.state.user.group.features["feature.marketwatch"] &&
        store.state.user.group.features["feature.marketwatch"] !== "disabled" &&
        (!store.state.user.group.features["feature.bustrack"] ||
          store.state.user.group.features["feature.bustrack"] === "disabled")
      ) {
        let marketWatchLinks = [];
        marketWatchLinks.push(
          {
            id: "sidebar-marketwatch-commodities",
            label: i18n.t("marketwatch.menu.commodities"),
            url: "/marketwatch/commodities",
            requireGlobalFeatures: ["marketwatch"],
          },
          {
            id: "sidebar-marketwatch-inputs",
            label: i18n.t("marketwatch.menu.inputs"),
            url: "/marketwatch/inputs",
            requireGlobalFeatures: ["marketwatch"],
          },
          {
            id: "sidebar-marketwatch-alerts",
            label: i18n.t("marketwatch.menu.alerts"),
            url: "/marketwatch/alerts",
            requireGlobalFeatures: ["marketwatch"],
          },
        );

        links.push({ marketwatch: marketWatchLinks });
      }

      let settingsProduct = {};
      settingsProduct["settingsMenuTitle"] = [
        {
          id: "sidebar-settings-farm-profile",
          label: i18n.t("farmProfile.title"),
          url: "/farm/profile",
        },
        {
          id: "sidebar-settings-users",
          label: i18n.t("users.title"),
          url: "/farm/groups/users",
        },
        {
          id: "sidebar-settings-user-profile",
          label: i18n.t("profile.title"),
          url: "/user/profile",
        },
        // To uncomment when https://yagro.com/en/release-notes page will be live
        // {
        //   id: "sidebar-settings-release-notes",
        //   label: i18n.t("release.title"),
        //   url: "/user/release-notes",
        // },
      ];
      links.push(settingsProduct);
    } catch (e) {
      console.log(e);
      throw e;
    }
    return this.reduceLinks(links, store);
  },

  supplierLinks(_, store, admin) {
    let links = [];
    links.push({
      marketplace: [
        {
          label: "Requests",
          url: "/distributor/quotes",
          badge: "open_requests",
          id: "sidebar-requests",
          requireGlobalFeatures: ["marketplace"],
        },
        {
          label: "Orders",
          url: "/distributor/quotes/orders",
          badge: "quoted_requests",
          id: "sidebar-quoted-requests",
          requireGlobalFeatures: ["marketplace"],
        },
        {
          label: "Quote history",
          url: "/distributor/quotes/history",
          requireGlobalFeatures: ["marketplace"],
        },
      ],
    });

    // marketwatch
    if (store.getters["settings/marketwatch"]) {
      let marketWatchLinks = [];

      marketWatchLinks.push(
        {
          id: "sidebar-marketwatch-commodities",
          label: i18n.t("marketwatch.menu.commodities"),
          url: "/marketwatch/commodities",
          requireGlobalFeatures: ["marketwatch"],
          feature: FEATURE_TYPE.MARKETWATCH,
        },
        {
          id: "sidebar-marketwatch-inputs",
          label: i18n.t("marketwatch.menu.inputs"),
          url: "/marketwatch/inputs",
          requireGlobalFeatures: ["marketwatch"],
          feature: FEATURE_TYPE.MARKETWATCH,
        },
        {
          id: "sidebar-marketwatch-alerts",
          label: i18n.t("marketwatch.menu.alerts"),
          url: "/marketwatch/alerts",
          requireGlobalFeatures: ["marketwatch"],
          feature: FEATURE_TYPE.MARKETWATCH,
        },
      );

      links.push({ marketwatch: marketWatchLinks });
    }

    let productLinks = {};
    productLinks["productsMenuTitle"] = [
      {
        label: i18n.t("sidebar.productsMenuTitle"),
        url: "/distributor/productlist",
        id: "sidebar-product-list",
      },
    ];
    if (admin) {
      productLinks["productsMenuTitle"].push({
        label: i18n.t("products.productFilters"),
        url: "/distributor/productfilters",
        id: "sidebar-product-filters",
      });
    }
    links.push(productLinks);

    let settingsProduct = {};
    settingsProduct["settingsMenuTitle"] = [
      {
        id: "sidebar-distributor-profile",
        label: i18n.t("company.companyInformation"),
        url: "/distributor/profile",
      },
      {
        id: "sidebar-distributor-group-users",
        label: "Users",
        url: "/distributor/groups/users",
      },
      {
        id: "sidebar-user-profile",
        label: i18n.t("profile.title"),
        url: "/user/profile",
      },
    ];
    if (admin && store.getters["settings/marketplace"]) {
      settingsProduct["settingsMenuTitle"].unshift({
        id: "sidebar-farms-serviced",
        label: i18n.t("company.farmsServiced"),
        url: "/supplier/farms",
      });
      settingsProduct["settingsMenuTitle"].unshift({
        id: "sidebar-delivery-locations",
        label: i18n.t("company.areasServiced"),
        url: "/distributor/deliverylocations",
      });
    }
    links.push(settingsProduct);
    return this.reduceLinks(links, store);
  },

  productLinks(_, store) {
    return this.reduceLinks(
      [
        {
          id: "sidebar-products-list",
          products: [
            {
              label: i18n.t("products.productList"),
              url: "/farm/products/view",
            },
          ],
        },
      ],
      store,
    );
  },

  partnerLinks(_, store) {
    let links = [];
    let dataLinks = [];

    dataLinks.push({
      id: "sidebar-data-upload",
      label: "Uploads",
      url: "/farm/data/upload",
    });
    links.push({ Data: dataLinks });

    let groupLinks = [
      {
        label: "Managed Groups",
        url: "/partner/groups",
        id: "sidebar-partner-groups",
      },
    ];
    links.push({ Groups: groupLinks });

    let supplyLinks = [
      {
        label: "Contracts",
        url: "/partner/supply/contracts",
        id: "sidebar-partner-groups",
      },
    ];
    links.push({ Supply: supplyLinks });

    let settingsProduct = {};
    settingsProduct["settingsMenuTitle"] = [
      // {label: i18n.t("company.companyInformation"), url: "/farm/profile"},
      {
        id: "sidebar-manage-users",
        label: "Users",
        url: "/distributor/groups/users",
      },
      {
        id: "sidebar-user-profile",
        label: i18n.t("profile.title"),
        url: "/user/profile",
      },
    ];
    links.push(settingsProduct);

    return this.reduceLinks(links, store);
  },
};
export default sidebarLinks;
