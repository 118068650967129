/**
 * Vue directive that triggers a function when a click event occurs outside of the element.
 * @module click-outside
 * @param {Object} el - The element to which the directive is bound.
 * @param {Object} binding - The binding object.
 * @param {Object} vnode - The virtual node object.
 * @param {Function} binding.expression - The function to be called when a click event occurs outside of the element.
 * @returns {void}
 * @link https://stackoverflow.com/a/42389266
 */

import Vue from 'vue';

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});
