import axios from "axios";
import store from "@/stores";

async function syncVersion() {
  await axios
    .get(`${store.state.settings.apiLegacyBase}/api/shared/settings/site`)
    .then((response) => {

      const currentVersion = store.state.settings.backend_info.version;
      const responseVersion = response.data.site.version;

      if (currentVersion !== responseVersion) location.reload();
    })
    .catch((error) => {
      console.error("Error while fetching site settings", error);
    });
}

export default syncVersion;
