<script setup>
import { computed, ref } from 'vue';
import YTasksList from '@s/YTasks/YTasksList/YTasksList.vue';
import store from '@/stores';
import YCounter from '@s/YCounter/YCounter.vue';
import YIcon from '@s/YIcon.vue';
import YButtonNew from '@s/YButtonNew.vue';

const isRunningTasks = computed(() => {
  return ! store.state.tasks.isIdle;
});

const getTotalTasks = computed(() => {
  return store.getters['tasks/getTotalTasks'];
});

const isAbove = ref(false);

function toggleAbove() {
  isAbove.value = !isAbove.value;
}

const containerClasses = computed(() => {
  return {
    'ÿ-translate-y-[calc(100%)]': getTotalTasks.value === 0,
    'ÿ-translate-y-0': isAbove.value && getTotalTasks.value > 0,
    'ÿ-translate-y-[calc(100%-3.5rem)]': !isAbove.value && getTotalTasks.value > 0,
  };
});

const expandButtonClasses = computed(() => {
  return {
    'ÿ-rotate-180': isAbove.value,
  };
});

</script>

<template>
  <div
    class="ÿ-transition ÿ-fixed ÿ-left-8 ÿ-bottom-0 ÿ-z-2000 ÿ-shadow-lg"
    :class="containerClasses"
    data-qa="y-tasks"
  >
    <div class="ÿ-w-80 ÿ-overflow-hidden ÿ-rounded-1 ÿ-bg-neutral-100 ÿ-font-size-3 ÿ-text-neutral-800 ÿ-font-sans">
      <header class="ÿ-h-14 ÿ-flex ÿ-items-center ÿ-justify-between ÿ-b-b-1 ÿ-b-b-neutral-200 ÿ-b-b-solid ÿ-px-4 ÿ-py-4 ÿ-leading-none">
        <div class="ÿ-flex ÿ-gap-2 ÿ-items-center">
          <YCounter :number="getTotalTasks" />
          <h4 class="ÿ-font-700 ÿ-font-size-3.5 ÿ-m-0">
            Bulk actions
          </h4>
          <div
            v-show="isRunningTasks"
            class="ÿ-text-yagro-greenLight ÿ-w-6"
          >
            <YIcon name="icon-tail-spin" />
          </div>
        </div>
        <div class="ÿ-flex ÿ-gap-2">
          <button
            class="ÿ-transition ÿ-w-8 ÿ-h-8 ÿ-flex ÿ-items-center ÿ-justify-center ÿ-rounded-full hover:ÿ-bg-yagro-greenLight hover:ÿ-text-white"
            :class="expandButtonClasses"
            @click="toggleAbove"
          >
            <i class="i-fa6-solid-chevron-up ÿ-block ÿ-text-inherit" />
          </button>
        </div>
      </header>
      <div class="ÿ-bg-neutral-50 ÿ-p-4">
        <YTasksList />
      </div>
      <footer class="ÿ-flex ÿ-items-center ÿ-justify-between ÿ-b-t-1 ÿ-b-t-neutral-200 ÿ-b-t-solid ÿ-px-4 ÿ-py-4 ÿ-leading-none">
        <YButtonNew
          text="Cancel all actions"
          size="small"
          icon="i-fa6-solid-trash"
          theme="primary"
          title="Cancels all actions"
          @click="store.dispatch('tasks/removeAllTasks')"
        />
      </footer>
    </div>
  </div>
</template>
