<script setup>
import { computed } from 'vue';

const props = defineProps({
  currentPage: {
    type: Number,
    default: 1,
  },
  totalPages: {
    type: Number,
    default: 1,
  },
});

const displayNext = computed(() => props.currentPage < props.totalPages);
const displayPrev = computed(() => props.currentPage > 1);

const emits = defineEmits(['prevPage', 'nextPage', 'clearAllAlerts']);

function prevPage() {
  emits('prevPage');
}

function nextPage() {
  emits('nextPage');
}

function clearAllAlerts() {
  emits('clearAllAlerts');
}
</script>

<template>
  <div class="y-alert-controls">
    <div class="y-alert-controls__body">
      <div class="y-alert-controls__text">
        <span data-qa="y-alert-controls__current-page">{{ currentPage }}</span> / <span data-qa="y-alert-controls__total-pages">{{ totalPages }}</span>
      </div>

      <div class="y-alert-controls__controls">
        <div class="y-alert-controls__control y-alert-controls__control--prev">
          <button
            :disabled="!displayPrev"
            class="y-alert-controls__button"
            data-qa="y-alert-controls__button-prev"
            @click="prevPage()"
          >
            <span class="sr-only">Previous</span>
            <span class="y-alert-controls__icon">
              <font-awesome-icon :icon="['far', 'chevron-left']" />
            </span>
          </button>
        </div>
        <div class="y-alert-controls__control y-alert-controls__control--next">
          <button
            :disabled="!displayNext"
            class="y-alert-controls__button"
            data-qa="y-alert-controls__button-next"
            @click="nextPage()"
          >
            <span class="sr-only">Next</span>
            <span class="y-alert-controls__icon">
              <font-awesome-icon :icon="['far', 'chevron-right']" />
            </span>
          </button>
        </div>
        <div class="y-alert-controls__control y-alert-controls__control--clear">
          <button
            class="y-alert-controls__button"
            data-qa="y-alert-controls__button-clear"
            @click="clearAllAlerts()"
          >
            <span class="sr-only">Clear all alerts</span>
            <span class="y-alert-controls__icon">
              <font-awesome-icon :icon="['far', 'trash-alt']" />
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.y-alert-controls {
    width: 100%;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: var(--font-bold);
    font-size: 0.75rem;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 0.25rem;
    overflow: hidden;
    padding: 0.25rem;
    box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.1);
    pointer-events: auto;
}

.y-alert-controls__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.y-alert-controls__text {
    margin: 0;
    padding: 0.5rem;
}

.y-alert-controls__control {
    display: flex;
    align-items: center;
    appearance: none;
}

.y-alert-controls__controls {
    display: flex;
    align-items: center;
    appearance: none;
    gap: 0.5rem;
}

.y-alert-controls__button {
    border: 1px solid var(--color-border);
    padding: 0.5rem 1rem;
    background: var(--color-white);
    color: var(--color-text);
    cursor: pointer;
    border-radius: 0.25rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;


    &[disabled] {
        cursor: not-allowed;
        opacity: 0.25;
    }

    &:hover {
        background: var(--primary-colour);
        color: var(--color-white);
        transition: color var(--transition-fastest), background-color var(--transition-fastest);
    }
}



.y-alert-controls__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform var(--transition-fastest);
    .y-alert-controls__control--prev:hover & {
        transform: translateX(-0.125rem);
    }

    .y-alert-controls__control--next:hover & {
        transform: translateX(0.125rem);
    }

    .y-alert-controls__control--clear:hover & {
        transform: translateY(-0.125rem);
    }
}

</style>
