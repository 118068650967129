<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  textWeight: {
    type: String,
    default: 'medium',
  },
  icon: {
    type: String,
    default: '',
  },
  iconPosition: {
    type: String,
    default: 'left',
  },
  size: {
    type: String,
    default: 'medium',
  },
  theme: {
    type: String,
    default: 'primary',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'button',
  },
  block: {
    type: Boolean,
    default: false,
  },
});

const dynamicClasses = computed(() => {

  const themeClasses = {
    'primary': ' ÿ-text-white ÿ-bg-button-primary hover:ÿ-bg-button-primary-hover',
    'secondary': 'ÿ-text-button-secondary ÿ-bg-transparent hover:ÿ-text-white hover:ÿ-bg-button-secondary-hover',
    'tertiary': 'ÿ-text-button-tertiary ÿ-bg-transparent hover:ÿ-text-white hover:ÿ-bg-button-tertiary-hover',
    'quaternary': 'ÿ-text-white ÿ-bg-button-quaternary hover:ÿ-text-white hover:ÿ-bg-button-quaternary-hover',
    'warning': 'ÿ-text-white ÿ-bg-button-warning hover:ÿ-text-white hover:ÿ-bg-button-warning-hover',
    'danger': 'ÿ-text-white ÿ-bg-button-danger hover:ÿ-text-white hover:ÿ-bg-button-danger-hover',
  }[props.theme];

  const iconPosition = { 'left': '', 'right': 'ÿ-flex-row-reverse' }[props.iconPosition];
  const textWeight = { 'medium': 'ÿ-font-medium', 'semibold': 'ÿ-font-semibold' }[props.textWeight];
  const size = { 'small': 'ÿ-font-size-3', 'medium': 'ÿ-font-size-3.5', 'large': 'ÿ-font-size-4' }[props.size];
  const disabled = props.disabled ? 'ÿ-cursor-not-allowed ÿ-opacity-50' : '';
  const block = props.block ? 'ÿ-w-full' : '';

  return `${themeClasses} ${iconPosition} ${textWeight} ${size} ${disabled} ${block}`;
});

const emits = defineEmits(['click']);

</script>

<template>
  <button
    class="ÿ-group/button ÿ-transition-colors ÿ-border-solid ÿ-border-1 ÿ-py-0.75em ÿ-px-1.25em ÿ-flex ÿ-gap-2 ÿ-items-center ÿ-justify-center ÿ-appearance-none ÿ-rounded-2 ÿ-leading-none"
    :class="dynamicClasses"
    :disabled="disabled"
    :type="type"
    @click="$emit('click')"
  >
    <span
      v-if="icon"
      class="ÿ-transition-transform ÿ-border-white ÿ-border-opacity-25"
    >
      <i
        class="ÿ-pointer-events-none"
        :class="icon"
      />
    </span>
    <span
      v-if="text"
      class="ÿ-whitespace-nowrap"
      v-html="text"
    />
  </button>
</template>
