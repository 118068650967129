let unauthenticatedRoutes = [
  {
    path: "/session/user/invitation/:invitation_id/:partner_id",
    component: () => import('@c/shared/users/Invitation'),
    name: "Invitation",
    props: true,
  },
  {
    path: "/session/user/reset-password/:reset_id/:partner_id",
    component: () => import('@c/shared/users/ResetPassword'),
    name: "Reset Password",
    props: true,
  },
  {
    path: "/session/user/group-invite",
    component: () => import('@c/shared/users/GroupInvitation'),
    name: "Group Invite",
    props: true,
  },
];

export default unauthenticatedRoutes;
