<template>
  <img
    :id="`${partnerId}-logo`"
    class="logo"
    :src="image"
    :alt="`${partnerId}-logo`"
  >
</template>

<script>
import { getBranding } from "@/branding";
import frontier from "@/assets/frontier-logo.png";
import kws from "@/assets/kws-logo.png";
import niab from "@/assets/niab-logo.png";
import savills from "@/assets/savills-logo.png";
import wilsonWraight from "@/assets/wilson-wraight-logo.png";

export default {
  data() {
    let partnerId = getBranding();
    return {
      partnerId,
      yagroLogoSrc: '/images/logo-yagro.svg',
    };
  },
  computed: {
    image() {
      switch(this.partnerId) {
      case 'frontier':
        return frontier;
      case 'kws':
        return kws;
      case 'niab':
        return niab;
      case 'savills':
        return savills;
      case 'wilson-wraight':
        return wilsonWraight;
      default:
        return this.yagroLogoSrc;
      }
    },
  },

  created() {
    this.$events.on("branding-changed", (partnerId) => {
      this.partnerId = partnerId;
    });
  },
}
</script>
<style>
.logo{
  max-height: 100px;
}
</style>
