import {load_languages} from "@/languages";

export function changeBranding(partner_id, vue_vm) {
  let old_branding = getBranding()
  if (old_branding !== partner_id) {
    if (partner_id) {
      localStorage.setItem('partnerId', partner_id);
    } else {
      localStorage.setItem('partnerId', "yagro");
    }

    load_languages();

    vue_vm.$events.fire("branding-changed", partner_id);
  }
}


export function getBranding() {
  let partnerId = localStorage.getItem('partnerId');
  if (partnerId) {
    return partnerId;
  }
  return "yagro";
}
