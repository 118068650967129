/*
-------------------------------------------------------------------------------
YAlerts
-------------------------------------------------------------------------------
Description
This adds $YAlert to Vue, so that you can use it in your components.

-------------------------------------------------------------------------------
Usage
-------------------------------------------------------------------------------
In your component, you can use $YAlert like this:

this.$YAlert.addAlert({
  mode: "success",
  title: "Success",
  text: "This is a success message.",
});
-------------------------------------------------------------------------------
*/

export default {
  install(Vue, options) {
    /* Check that the store is accessible */
    if (!options || !options.store) {
      throw new Error("y-alerts: Please provide a Vuex store instance.");
    }

    /* Access the store */
    const store = options.store;

    /* Define the YAlert object, and add associated methods. */
    const YAlert = {
      addAlert(alert) {
        if (!alert) {
          throw new Error("y-alerts: Please provide an alert object.");
        }
        store.dispatch("alerts/requestToAddAlert", alert);
      },
      removeAlert(id) {
        if (!id) {
          throw new Error("y-alerts: Please provide an alert id.");
        }
        store.dispatch("alerts/requestToRemoveAlert", id);
      },
      testAlerts() {
        const max = 20;
        for (let i = 0; i < max; i++) {
          const modes = ["info", "success", "warning", "danger"];
          this.addAlert({
            title: `Alert ${i + 1}`,
            text: `This is alert #${i + 1}`,
            mode: modes[Math.floor(Math.random() * modes.length)],
            isDismissible: true,
            autoDismiss: false,
          });
        }
      },
    };

    /* Add the $YAlert object to the Vue prototype */
    Vue.prototype.$YAlert = YAlert;

    /* Add component to the window for debugging purposes */
    window.YAlert = YAlert;
  },
};
