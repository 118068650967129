import Vue from "vue";
import VueI18n from "vue-i18n";
import enGB from "./en-gb.js";

const messages = {
  "en-gb": enGB,
};

Vue.use(VueI18n);

export const i18n = new VueI18n({
  fallbackLocale: "en-gb",
  messages: messages,
  silentFallbackWarn: true,
});
