<template>
  <div :class="`wrapper branding-${partnerId}`">
    <portal-target
      name="destination"
      multiple
    />
    <YTasks />
    <YAlertList v-if="amountOfAlerts > 0" />
    <navbar
      v-if="user_authenticated"
      @togglesidebar="toggleSidebar"
    />
    <div
      id="main-div"
      class="page"
      :class="{
        'logged-in': user_authenticated,
        'off-canvas-menu': showSidebar,
      }"
    >
      <template v-if="user_authenticated">
        <sidebar-base
          :links="sidebarLinks"
          @hidesidebar="hideSidebar"
          @togglesidebar="toggleSidebar"
        />
      </template>

      <main id="app">
        <page-loading-bar />
        <router-view :key="$route.fullPath + activeGroup" />
        <platform-footer v-if="user_authenticated" />
      </main>

      <div
        v-if="user_authenticated"
        class="overlay"
        @click="hideSidebar"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

import Navbar from "./components/partials/Navbar";
import SidebarBase from "./components/shared/SidebarBase";
import PlatformFooter from "./PlatformFooter";
import PageLoadingBar from "./PageLoadingBar";
import auth from "./auth";
import UserStateMixin from "./components/shared/mixins/UserStateMixin";
import BlobMixin from "./components/shared/mixins/BlobMixin";
import PlatformSidebarMixin from "./components/shared/mixins/PlatformSidebarMixin";
import RouteMixin from "./components/shared/mixins/RouteMixin";
import NotificationGetMixin from "./components/shared/mixins/NotificationGetMixin";
import RequestProductMixin from "./components/shared/mixins/RequestProductMixin";
import {changeBranding, getBranding} from "@/branding";
import {i18n} from "@/plugins/i18n";
import YAlertList from "@c/shared/YAlertList";
import YTasks from "@s/YTasks/YTasks/YTasks.vue";

export default {
  components: {
    Navbar,
    SidebarBase,
    PlatformFooter,
    PageLoadingBar,
    YAlertList,
    YTasks,
  },
  mixins: [
    UserStateMixin,
    NotificationGetMixin,
    RouteMixin,
    PlatformSidebarMixin,
    BlobMixin,
    RequestProductMixin,
  ],
  data() {
    return {
      partnerId: "yagro",
      showSidebar: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters('alerts', ['amountOfAlerts']),
    activeGroup() {
      return this.$store.state.user.group.id;
    },
  },
  created() {
    let partnerId = this.$route.query.partner_id;
    if (!partnerId) {
      partnerId = "yagro";
    }
    changeBranding(partnerId, this);
    this.partnerId = getBranding()

    const self = this;
    this.$router.beforeEach((to, from, next) => {
      // scroll to top
      window.scrollTo(0, 0);
      if (self.routeCheck(to.path)) {
        this.$nextTick(() => {
          this.$events.fire("update-notifications");
        });
        next();
      }
    });

    this.registerEvents();
    this.$router.onReady(()=> {
      auth.checkAuth(null, null);
      this.routeCheck(this.getPath());
    })

    if (this.user_authenticated) {
      // Fire a notification update
      this.$nextTick(() => {
        this.$events.fire("update-notifications");
      });
    }
  },
  mounted() {
    this.startWatchingTasks(true);
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 1024) {
        this.showSidebar = false;
      }
    };
  },
  methods: {
    ...mapActions('tasks', ['startWatchingTasks']),
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    hideSidebar() {
      this.showSidebar = false;
    },
    routeCheck(path) {
      if (this.user_authenticated) {
        if (path.startsWith("/session")) {
          console.log("Router logout");
          auth.logout(path);
          return false;
        } else if (path.startsWith("/users/login")) {
          this.gotoRoot();
          return false;
        } else if (path === "/public/positions") {
          this.navigateTo({ name: 'Market Positions' });
          return false;
        }
      } else {
        if (path.startsWith("/session") || path.startsWith("/public")) {
          return true;
        } else if (!path.startsWith("/users/login")) {
          return false;
        }
      }
      return true;
    },
    refresh() {
      auth.refresh();
    },
    reload() {
      window.location.reload();
    },
    logout() {
      let branding = getBranding()
      if (branding && branding !== 'yagro') {
        auth.logout(`/users/login/${branding}`);
      } else {
        auth.logout("/users/login");
      }
    },
    registerEvents() {
      const self = this;
      // register event to update the notifications
      this.$events.on("update-notifications", () => {
        self.getNotifications();
      });
      this.$events.on("branding-changed", (partnerId) => {
        document.title = i18n.t('config.tabTitle');
        self.partnerId = partnerId;
      });

      this.$events.on("app-logout", () => {
        self.logout();
      });

      this.$events.on("app-refresh", () => {
        self.refresh();
      });

      this.$events.on("app-update", () => {
        self.reload();
      });

      this.$events.on("change-group", group => {
        self.changeGroup(group);
      });
      // TODO remove this function when all invoices are stored in the doc system
      this.$events.on("load-invoice", invoiceId => {
        self.loadInvoice(invoiceId);
      });

      this.$events.on("add-product-request", product => {
        self.addProductToRequest(product);
      });

      // Register listener for service worker events
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.addEventListener("message", event => {
          const targetEvent = JSON.parse(event.data);
          this.$bus.$emit(targetEvent.event);
        });
      }
    },
    loadInvoice(invoiceId) {
      // TODO remove this function when all invoices are stored in the doc system
      this.loadFile(`${this.$store.state.settings.apiLegacyBase}/api/invoice/download/${invoiceId}`);
    },
    loadFile(url) {
      axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then(response => {
          this.openBlobNewWindow(
            [response.data],
            "invoice.pdf",
            "application/pdf",
          );
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/app.scss';
</style>
