<script>
/**
   * Notification State Mixin
   * Exposes notifications to the components
   */
export default {
  computed: {
    open_requests() {
      return this.$store.state.notifications.open_requests;
    },
    quoted_requests() {
      return this.$store.state.notifications.quoted_requests;
    },
    virtualgroup_invites() {
      return this.$store.state.notifications.virtualgroup_invites;
    },
  },
};
</script>
