import cloneDeep from "lodash/cloneDeep";

/**
 * State of the Store Explorer module.
 * @type {Object}
 */
const explorerState = {
  averageCop: {},
};

/**
 * Mutations for the Store Explorer module.
 * @type {Object}
 */
const explorerMutations = {
  /**
   * Changes the average CoP (Cost of Production) in the state.
   * @param {Object} state The current state of the module.
   * @param {Object} payload The new value for the average CoP.
   */
  CHANGE_AVERAGE_COP(state, payload) {
    state.averageCop = payload;
  },
  /**
   * Resets the state to its default values.
   * @param {Object} state The current state of the module.
   */
  RESET_STATE(state) {
    Object.assign(state, cloneDeep(explorerState));
  },
};

/**
 * Actions for the Store Explorer module.
 * @type {Object}
 */
const explorerActions = {
  /**
   * Requests to change the CoP (Center of Pressure).
   * @param {Object} context The Vuex action context.
   * @param {Object} payload The payload to change the CoP.
   */
  requestToChangeCoP({ commit }, payload) {
    commit("CHANGE_AVERAGE_COP", payload);
  },
  /**
   * Action to reset the state to its default values.
   * @param {Object} context The Vuex action context.
   */
  resetState({ commit }) {
    commit("RESET_STATE");
  },
};

/**
 * Getters for the Store Explorer module.
 * @type {Object}
 */
const explorerGetters = {
  /**
   * Gets the average CoP (Cost of Production) from the state.
   * @param {Object} state The current state of the module.
   * @returns {Object} The average CoP.
   */
  getAverageCoP(state) {
    return state.averageCop;
  },
};

/**
 * Vuex module for user-related data and operations.
 * @type {Object}
 */
const explorerModule = {
  namespaced: true,
  state: explorerState,
  getters: explorerGetters,
  mutations: explorerMutations,
  actions: explorerActions,
};

export default explorerModule;
