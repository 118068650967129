let analyticsRoutes = [
  {
    path: "/feature/analytics",
    component: () => import("@c/shared/AnalyticsFeature"),
    name: "Analytics Feature",
  },
  {
    path: "/farm/analytics/explorer",
    component: () => import("@/pages/farm/explorer/ExplorerSummary"),
    name: "Analytics Explorer",
  },
  {
    path: "/farm/analytics/explorer/year/:year",
    component: () => import("@/pages/farm/explorer/ExplorerSummary"),
    name: "Yagro Explorer - Dynamic",
  },
  {
    path: "/farm/analytics/explorer/detail",
    component: () => import("@/pages/farm/explorer/ExplorerDetail"),
    name: "Yagro Explorer Detail",
  },
  {
    path: "/farm/analytics/explorer/year/:year/crop/:crop",
    component: () => import("@/pages/farm/explorer/ExplorerDetail"),
    name: "Yagro Explorer Detail - Year, Crop",
  },
  {
    path: "/farm/analytics/explorer/year/:year/crop/:crop/nth-crop/:nthCrop",
    component: () => import("@/pages/farm/explorer/ExplorerDetail"),
    name: "Yagro Explorer Detail - Year, Crop, 1st and 2nd",
  },
  {
    path: "/farm/analytics/explorer/year/:year/crop/:crop/end-use-market/:endUseMarket",
    component: () => import("@/pages/farm/explorer/ExplorerDetail"),
    name: "Yagro Explorer Detail - Year, Crop, End-use Market",
  },
  {
    path: "/farm/analytics/explorer/year/:year/crop/:crop/end-use-group/:endUseGroup",
    component: () => import("@/pages/farm/explorer/ExplorerDetail"),
    name: "Yagro Explorer Detail - Year, Crop, End-use Group",
  },
  {
    path: "/farm/analytics/explorer/year/:year/crop/:crop/variety/:variety/",
    component: () => import("@/pages/farm/explorer/ExplorerDetail"),
    name: "Yagro Explorer Detail - Year, Crop, Variety",
  },
  {
    path: "/farm/analytics/explorer/year/:year/crop/:crop/variety/:variety/field/:field",
    component: () => import("@/pages/farm/explorer/ExplorerDetail"),
    name: "Yagro Explorer Detail - Year, Crop, Variety, Field",
  },
  {
    path: "/farm/analytics/virtual-groups",
    component: () => import("@/pages/farm/virtualgroups/Overview"),
    name: "Analytics Virtual Groups",
  },
  {
    path: "/farm/analytics/virtual-groups/group/:vgid/year/:year",
    component: () => import("@/pages/farm/virtualgroups/Overview"),
    name: "Virtual Groups - Group, Year",
  },
  {
    path: "/farm/analytics/virtual-groups/group/:vgid/year/:year/crop/:crop",
    component: () => import("@/pages/farm/virtualgroups/Overview"),
    name: "Virtual Groups - Group, Year, Crop",
  },
  {
    path: "/farm/analytics/virtual-groups/manage",
    component: () => import("@/pages/farm/virtualgroups/Manager"),
    name: "Virtual Groups Manager",
  },
  {
    path: "/farm/analytics/pricecheck",
    component: () => import("@c/shared/PriceCheckOverview"),
    name: "Analytics Price Check Overview",
  },
  {
    path: "/farm/analytics/pricecheck/season/:season",
    component: () => import("@c/shared/PriceCheckOverview"),
    name: "Analytics Price Check Overview - Season",
  },
  {
    path: "/farm/analytics/myprices",
    component: () => import("@c/shared/PriceCheckMain"),
    name: "Analytics Farm Price Data",
  },
  {
    path: "/farm/analytics/myprices/season/:season",
    component: () => import("@c/shared/PriceCheckMain"),
    name: "Analytics Farm Price Data - Season",
  },
  {
    path: "/farm/analytics/field-by-field",
    component: () =>
      import("@c/Farm/fieldbyfieldanalytics/FieldByFieldAnalytics"),
    name: "Field By Field Analytics",
  },
  {
    path: "/farm/analytics/field-by-field/year/:year",
    component: () =>
      import("@c/Farm/fieldbyfieldanalytics/FieldByFieldAnalytics"),
    name: "Field By Field Analytics - Year",
  },
  {
    path: "/farm/analytics/reports",
    component: () => import("@/pages/farm/analytics/AnalyticsReports"),
    name: "Analytics Reports",
  },
];

export default analyticsRoutes;
