export const statusClasses = {
  active: "is-active",
  disabled: "is-disabled",
  hidden: "is-hidden",
  visible: "is-visible",
  loading: "is-loading",
  sticky: "is-sticky",
  highlighted: "is-highlighted",
  toHighlight: "to-highlight", // In preparation for highlighting
};
