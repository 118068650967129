/*
Dynamic base path so we can:
- Build these routes separately to current DIE routes.
- Easily change it later if we want to.
*/
const basePath = "data-management";

const routes = [
  {
    path: `/${ basePath }`,
    component: () => import('@/layouts/DataManagement.vue'),
    name: `${ basePath }`,
    meta: { adminOnly: true },
    children: [
      {
        path: 'view',
        component: () => import('@/pages/DataManagement/ViewData.vue'),
        name: `${ basePath }:view`,
      },
      {
        path: 'edit',
        component: () => import('@/pages/DataManagement/EditData.vue'),
        name: `${ basePath }:edit`,
      },
      {
        path: 'upload',
        component: () => import('@/pages/DataManagement/UploadData.vue'),
        name: `${ basePath }:upload`,
      },
    ],
  },
];

export { routes };
