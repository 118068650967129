<script>
/**
   * Blob Mixin
   * Helpers for working with blobs
   */

export default {
  methods: {
    openBlobNewWindow(data, filename, file_type) {
      const blob = new Blob(data, { type: file_type });
      // This lets it work on IE and Edge using MS specific APIs.
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
      } else {
        if ('webkitURL' in window) {
          // Workaround for chrome which can't download blobs
          const downloadLink = document.createElement('a');
          downloadLink.download = filename;
          downloadLink.innerHTML = 'Download File';
          downloadLink.href = window.webkitURL.createObjectURL(blob);
          downloadLink.click();
        } else {
          // all other browsers except MS Edge.
          const dlUrl = window.URL.createObjectURL(blob);
          window.location.href = dlUrl;
        }
      }
    },
    destroyClickedElement(event) {
      document.body.removeChild(event.target);
    },
  },
};
</script>
