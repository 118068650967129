let businesstrackerRoutes = [
  {
    path: "/farm/tracker/business-tracker",
    component: () => import('@/pages/farm/businesstracker/BusinessTracker'),
    name: "Business Tracker",
    children: [
      {
        path: "year/:year",
        component: () => import('@/pages/farm/businesstracker/BusinessTracker'),
        name: "Business Tracker - Year",
      },
    ],
  },
  {
    path: "/farm/tracker/business-tracker/year/:year/crop/:crop",
    component: () => import('@/pages/farm/businesstracker/BusinessTrackerCrop'),
    name: "Business Tracker - Year, Crop",
  },
  {
    path: "/farm/tracker/business-tracker/costs",
    component: () => import('@/pages/farm/businesstracker/BusinessTrackerCosts'),
    name: "Business Tracker Costs",
  },
  {
    path: "/farm/data/sales",
    component: () => import('@/pages/farm/businesstracker/BusinessTrackerSales'),
    name: "Business Tracker Sales",
  },
  {
    path: "/farm/data/contracts",
    component: () => import('@/pages/farm/businesstracker/BusinessTrackerContracts'),
    name: "Business Tracker Contracts",
  },
  {
    path: "/feature/tracker",
    component: () => import('@c/shared/BusinessTrackerFeature'),
    name: "BusinessTracker Feature",
  },
  {
    path: "/manager/budgets",
    component: () => import('@/pages/admin/budgets/AdminBudgets'),
    name: "BusinessTracker Budgets",
  },
];

export default businesstrackerRoutes;
