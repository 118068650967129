<template>
  <footer
    class="footer"
    role="contentinfo"
  >
    <p class="copyright text-center">
      © {{ getYear() }} - YAGRO Ltd. |
      <b-link :to="{ name: 'Terms and Conditions' }">
        {{ $t('terms.link') }}
      </b-link>
    </p>
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    getYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
