<template>
  <nav
    v-if="should_display_sidebar()"
    data-simplebar
    class="sidebar-menu"
  >
    <router-link
      :to="{ name: 'Dashboard Home' }"
      class="sidebar-logo"
    >
      <Logo />
    </router-link>

    <ul class="nav flex-column nav-stacked">
      <li
        v-if="show_group_select"
        class="nav__combo"
      >
        <group-select v-model="selected_group" />
      </li>
      <li
        v-if="isAdmin() && selected_group_id"
        class="nav-item p-3"
      >
        <b-button
          variant="outline-primary w-100"
          @click="goToAdmin"
        >
          {{ $t("sidebar.yagroAdmin") }}
        </b-button>
      </li>
      <li class="nav-item">
        <b-link
          :to="getDashboardLink()"
          class="nav-link"
          name="blah"
        >
          <span id="sidebar-dashboard">{{ $t("sidebar.dashboard") }}</span>
        </b-link>
      </li>

      <template v-for="link in links">
        <template v-for="(nav_group, heading) in link">
          <li
            :id="'li_sidebar_' + heading.replace(/ /g, '_').toLowerCase()"
            class="nav-item section-nav"
          >
            <a
              :id="'sidebar_' + heading.replace(/ /g, '_').toLowerCase()"
              v-b-toggle="'collapse_' + heading.replace(/ /g, '_')"
              href="#"
              class="nav-link"
            >
              <span
                v-if="$i18n && $i18n.messages['en-gb']['sidebar'][heading]"
              >{{ $t("sidebar." + heading) }}</span>
              <span v-else>{{ heading }}</span>
              <font-awesome-icon
                :icon="['far', 'chevron-down']"
                class="when-opened float-right"
                fixed-width
                transform="down-3"
              />
              <font-awesome-icon
                :icon="['far', 'chevron-right']"
                class="when-closed float-right"
                fixed-width
                transform="down-3"
              />
            </a>

            <b-collapse :id="'collapse_' + heading.replace(/ /g, '_')">
              <ul class="nav subnav flex-column">
                <li
                  v-for="nav_link in nav_group"
                  class="nav-item"
                  @click="hideSidebar()"
                >
                  <template>
                    <router-link
                      v-slot="{ href, isExactActive }"
                      :to="nav_link.url"
                      class="nav-link"
                      exact
                      custom
                    >
                      <a
                        :id="nav_link.id"
                        :href="href"
                      >
                        <span
                          :class="{ 'router-link-exact-active': isExactActive }"
                        >{{ nav_link.label }}</span>
                        <font-awesome-icon
                          v-if="nav_link.icon"
                          :icon="['far', `${nav_link.icon}`]"
                          pull="right"
                          fixed-width
                          transform="down-3"
                        />
                        <font-awesome-icon
                          v-if="nav_link.isLocked"
                          :icon="['far', 'lock-alt']"
                          pull="right"
                          fixed-width
                          transform="down-3"
                        />
                        <span
                          v-if="
                            nav_link.newUntil && new Date() < nav_link.newUntil
                          "
                          class="badge badge-secondary inline-badge"
                        >
                          {{ $t("sidebar.new") }}
                        </span>
                        <span
                          v-if="
                            nav_link.updatedUntil &&
                              new Date() < nav_link.updatedUntil
                          "
                          class="badge badge-secondary inline-badge ml-2"
                        >
                          {{ $t("sidebar.updated") }}
                          <font-awesome-icon
                            :icon="['far', 'sparkles']"
                            fixed-width
                          />
                        </span>
                        <span
                          v-if="
                            !nav_link.isLocked &&
                              nav_link.badge === 'open_requests' &&
                              open_requests > 0
                          "
                          class="badge badge-light-green mt-1 mr-1"
                        >
                          {{ open_requests }}
                        </span>

                        <span
                          v-else-if="
                            !nav_link.isLocked &&
                              nav_link.badge === 'quoted_requests' &&
                              quoted_requests > 0
                          "
                          class="badge badge-light-green mt-1 mr-1"
                        >
                          {{ quoted_requests }}
                        </span>

                        <span
                          v-else-if="
                            !nav_link.isLocked &&
                              nav_link.badge === 'virtualgroup_invites' &&
                              virtualgroup_invites > 0
                          "
                          id="virtual-group-invites-badge"
                          class="badge badge-light-green mt-1 mr-1"
                        >
                          {{ virtualgroup_invites }}
                        </span>
                      </a>
                    </router-link>
                  </template>
                </li>
              </ul>
            </b-collapse>
          </li>
        </template>
      </template>

      <li class="nav-item">
        <a
          id="logout"
          href="#"
          class="nav-link"
          @click="logout()"
        >
          <span>{{ $t("sidebar.logout") }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import GroupSelect from "../Manager/GroupSelect";
import NotificationStateMixin from "./mixins/NotificationStateMixin";
import UserStateMixin from "./mixins/UserStateMixin";
import AdminMixin from "./mixins/AdminMixin";
import NetworkModule from "../../lib/NetworkModule";
import simplebar from "simplebar-vue";
import "simplebar-vue/dist/simplebar.min.css";
import auth from "@/auth";
import Logo from "@/components/shared/Logo";
import { changeBranding } from "@/branding";

export default {
  components: {
    Logo,
    GroupSelect,
    NotificationStateMixin,
    UserStateMixin,
    AdminMixin,
    simplebar,
  },

  mixins: [NotificationStateMixin, UserStateMixin, AdminMixin],

  props: ["links"],

  data() {
    return {
      show_group_select: false,
      request_autocomplete_groups: new NetworkModule.SingleCancelRequest(),
    };
  },

  computed: {
    selected_group: {
      set(val) {
        // TODO: look into why sidebar is setting initial value for groups
        this.$events.fire("change-group", val);
      },
      get() {
        return this.group_obj;
      },
    },

    selected_group_id() {
      if (this.selected_group) {
        return this.selected_group.id;
      }
      return undefined;
    },
  },

  created() {
    this.showGroups();
  },

  methods: {
    isAdmin: auth.isAdmin,

    should_display_sidebar() {
      return (
        this.$store.getters["user/get_current_terms_accepted"] &&
        !this.$store.getters["user/get_force_reset_password"]
      );
    },

    is_group_selected() {
      return this.$store.state.user.group !== undefined;
    },

    getDashboardLink() {
      if (auth.isAdmin() && !this.is_group_selected()) {
        return "/admin-dashboard";
      }
      return "/dashboard";
    },

    logout() {
      this.hideSidebar();
      this.$events.fire("app-logout");
    },

    hideSidebar() {
      this.$emit("hidesidebar");
    },

    async showGroups() {
      const groupList = await this.$store.getters["user/get_group_list"];

      if (!Array.isArray(groupList)) return;

      if (
        !auth.isAdmin() &&
        (groupList.length === 1 ||
          (groupList.length > 1 && !this.selected_group?.["branding_id"]))
      ) {
        this.selected_group = groupList[0];
      }
      if (groupList.length > 1) {
        this.show_group_select = true;
      }
      changeBranding(this.selected_group["branding_id"], this);
    },

    toggleSidebar() {
      this.$emit("togglesidebar");
    },

    goToAdmin() {
      this.$store.commit("user/CHANGE_GROUP", {
        id: null,
        uuid: null,
        name: null,
        type: null,
      });
      this.$router.push({ name: "Dashboard" });
    },
  },
};
</script>

<style scoped>
.nav-link {
  font-size: 0.9rem;
}

.lock-size {
  font-size: 1.2em;
}

.nav > li > a {
  text-transform: capitalize;
}

.nav:not(.subnav) > li > a {
  color: #222;
  font-weight: 700;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.simplebar-scrollbar:before {
  background: #cac8cb;
}

.router-link-exact-active {
  font-weight: 500;
  color: black;
}
</style>
