import { nanoid } from 'nanoid';

/**
 * Default State
 */
const defaultState = () => ({
  pending: [],
  completed: [],
  failed: [],
  active: null,
  taskInterval: 1500,
  taskTimeout: 30000,
  forceReloadBrowserTimeout: 5000,
  isWatching: false,
  isIdle: true,
  confirmDialog: {
    show: false,
    title: '',
    message: '',
    additionalInfo: '',
    resolve: null,
    buttonText: 'Confirm',
  },
});

/**
 * Mutations
 */
const taskMutations = {
  RESET_STATE(state) {
    Object.assign(state, defaultState());
    // prettyLog('TASKS: State reset');
  },
  ADD_PENDING_TASK(state, payload) {
    const { text, action } = payload;
    const id = nanoid();

    const task = {
      id,
      text,
      action,
      refresh: payload.refresh || false,
      redirect: payload.redirect || false,
    };

    if (payload.filename) {
      task.filename = payload.filename;
    }

    if (payload.options) {
      task.options = payload.options;
    }

    state.pending.push(task);
    // prettyLog(`TASKS: Added task: ${text} - ${id}`);
  },
  REMOVE_PENDING_TASK(state, taskId) {
    state.pending = state.pending.filter(t => t.id !== taskId);
    // prettyLog(`TASKS: Removed task: ${taskId}`);
  },
  CLEAR_COMPLETED_TASKS(state) {
    state.completed = [];
    // prettyLog('TASKS: Cleared completed tasks');
  },
  SET_ACTIVE_TASK(state, task) {
    state.active = task;
    // prettyLog(`TASKS: Set active task: ${task.id}`);
  },
  ADD_COMPLETED_TASK(state, task) {
    state.completed.push(task);
    // prettyLog(`TASKS: Added completed task: ${task.id}`);
  },
  ADD_FAILED_TASK(state, task) {
    state.failed.push(task);
    // prettyLog(`TASKS: Added failed task: ${task.id}`);
  },
  SET_TASK_INTERVAL(state, interval) {
    state.taskInterval = interval;
    // prettyLog(`TASKS: Set task interval: ${interval}`);
  },
  SET_TASK_TIMEOUT(state, timeout) {
    state.taskTimeout = timeout;
    // prettyLog(`TASKS: Set task timeout: ${timeout}`);
  },
  SET_WATCHING(state, watching) {
    state.isWatching = watching;
    // prettyLog(`TASKS: Set watching: ${watching}`);
  },
  SET_IDLE_STATE(state, idle) {
    state.isIdle = idle;
    // prettyLog(`TASKS: Set idle state: ${idle}`);
  },
  SET_CONFIRMATION(state, { title, message, resolve }) {
    state.confirmDialog = {
      title,
      message,
      resolve,
      show: true,
    };
    // prettyLog(`TASKS: Set confirmation: ${message}`);
  },
  CLEAR_CONFIRMATION(state) {
    state.confirmDialog = {
      show: false,
      title: '',
      message: '',
      additionalInfo: '',
      resolve: null,
    };
    // prettyLog('TASKS: Cleared confirmation');
  },
};

/**
 * Actions
 */
const taskActions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },
  removeAllTasks({ dispatch }) {
    dispatch('resetState');
  },
  clearCompletedTasks({ commit }) {
    commit('CLEAR_COMPLETED_TASKS');
  },
  /*
  'Confirmations' - used to confirm / cancel actions before proceeding
   */
  async requestBatchConfirmation({ dispatch }, { title, message }) {
    return await dispatch('requestConfirmation', { title, message });
  },
  requestConfirmation({ commit }, { title, message }) {
    return new Promise((resolve) => {
      commit('SET_CONFIRMATION', { title, message, resolve });
    });
  },
  confirmAction({ state, commit }) {
    if (state.confirmDialog.resolve) {
      state.confirmDialog.resolve(true);
      commit('CLEAR_CONFIRMATION');
    }
  },
  cancelAction({ state, commit }) {
    if (state.confirmDialog.resolve) {
      state.confirmDialog.resolve(false);
    }
    commit('CLEAR_CONFIRMATION');
  },
  async addPendingTask({ commit, dispatch }, payload) {
    commit('ADD_PENDING_TASK', payload);
    dispatch('triggerTaskProcessing');
  },
  async performTask({ state, rootState, dispatch, commit }) {
    if (!state.active) return;

    const { action, options } = state.active;

    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Task timeout exceeded')), state.taskTimeout),
    );

    try {
      await Promise.race([
        dispatch(action, options || null, { root: true }),
        timeoutPromise,
      ]);

      if (state.active.refresh) {
        dispatch('tasksEphermal/setDataNeedsRefreshing', true, { root: true });
      }

      await dispatch('addCompletedTask', state.active);

      if (state.pending.length > 0) {
        setTimeout(() => {
          dispatch('processNextTask');
        }, state.taskInterval);
      } else {

        commit('SET_IDLE_STATE', true);

        if (state.active.redirect) {
          dispatch('forceRedirect', state.active.redirect);
        }

        if (rootState.tasksEphermal.dataNeedsRefreshing) {
          dispatch('forceReloadBrowser', { withWarning: true, withTimeout: true });
        }
      }
    } catch (error) {
      console.error('Task execution error:', error);
      vm.showMessage('error', `Task execution error: ${state.active.text}`);

      if (error.message === 'Task timeout exceeded') {
        vm.showMessage('warning', `Task timeout exceeded: ${state.active.text}, proceeding to next task.`);
        commit('ADD_FAILED_TASK', state.active);
      }

      if (state.pending.length > 0) {
        setTimeout(() => {
          dispatch('processNextTask');
        }, state.taskInterval);
      } else {
        commit('SET_IDLE_STATE', true);
      }
    }
  },
  setActiveTask({ commit, dispatch, state }, taskId) {
    const task = state.pending.find(t => t.id === taskId);
    if (task) {
      commit('SET_ACTIVE_TASK', task);
      dispatch('removePendingTask', task.id);
    }
  },
  runActiveTask({ dispatch }) {
    dispatch('performTask');
  },
  removePendingTask({ commit }, taskId) {
    commit('REMOVE_PENDING_TASK', taskId);
  },
  processNextTask({ dispatch, state }) {
    if (state.pending.length > 0) {
      const nextTask = state.pending[0];
      dispatch('setActiveTask', nextTask.id);
      dispatch('runActiveTask');
    }
  },
  async startWatchingTasks({ state, commit, dispatch }, reloaded) {
    if (reloaded) {
      commit('SET_WATCHING', false);
      commit('SET_IDLE_STATE', true);

      if (state.pending.length > 0) {
        YAlert.addAlert({
          mode: 'warning',
          title: 'Tasks Reloaded',
          text: 'Pending tasks will attempt to resume.',
          autoDismiss: true,
        });
      }
    }

    if (state.isWatching) return;
    commit('SET_WATCHING', true);
    dispatch('triggerTaskProcessing');
  },
  triggerTaskProcessing({ state, dispatch }) {
    if (state.isIdle && state.pending.length > 0) {
      dispatch('setIdleState', false);
      dispatch('processNextTask');
    }
  },
  setIdleState({ commit }, idle) {
    commit('SET_IDLE_STATE', idle);
  },
  setTaskInterval({ commit }, interval) {
    commit('SET_TASK_INTERVAL', interval);
  },
  setTaskTimeout({ commit }, timeout) {
    commit('SET_TASK_TIMEOUT', timeout);
  },
  addCompletedTask({ commit }, task) {
    commit('ADD_COMPLETED_TASK', task);
  },
  forceReloadBrowser({ state }, options) {
    const { withWarning, withTimeout } = options || {};

    if (withWarning) {
      vm.showMessage('warning', `Forcing reload in ${state.forceReloadBrowserTimeout / 1000} second(s).`);
    }

    if (withTimeout) {
      setTimeout(() => {
        window.location.reload();
      }, state.forceReloadBrowserTimeout);
    } else {
      window.location.reload();
    }
  },
  forceRedirect({ state }, route) {
    vm.$router.push(route);
  },
};

/**
 * Getters
 */
const taskGetters = {
  getPendingTasks: state => state.pending,
  getCompletedTasks: state => state.completed,
  getFailedTasks: state => state.failed,
  getActiveTask: state => state.active,
  getTaskInterval: state => state.taskInterval,
  getTaskTimeout: state => state.taskTimeout,
  getTotalTasks: state => state.pending.length,
  isWatching: state => state.isWatching,
  isIdle: state => state.isIdle,
};

/**
 * Setup
 */
const StoreTasksModule = {
  namespaced: true,
  state: defaultState(),
  mutations: taskMutations,
  actions: taskActions,
  getters: taskGetters,
};

export default StoreTasksModule;
