import Vue from "vue";
import * as Sentry from "@sentry/vue";
import store from "@/stores";

let sentry_dsn = import.meta.env.SENTRY_DSN;
if (!sentry_dsn) {
  // TODO don't harded code this, pass it it
  sentry_dsn = "https://2d825b4c6fe74295bcf01b5d325dfd5b@o227217.ingest.sentry.io/5676129";
}

const IGNORE_MESSAGES = [
  /Validating a non-existent field/i,
  /Request failed with status code 40/i,
  /Navigating to current location.+is not allowed/i,
  /UnhandledRejection/i,
  /Redirected when going from.+via a navigation guard./i,
  /Request aborted/i,
  /Avoided redundant navigation to current location/i,
]

const sentry = {
  init(router) {
    /* Disables Sentry locally, as errors don't always appear in console while active. */
    if(import.meta.env.MODE === 'development') {
      console.info("Sentry disabled in development mode.");
      return;
    }
    const site = store.getters['settings/env_name'];

    if (site !== "Unknown" && site !== undefined && sentry_dsn) {

      window.yagroSentry = Sentry.init({
        Vue,
        dsn: sentry_dsn,
        environment: site,
        release: import.meta.env.SENTRY_VERSION,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
          Sentry.browserTracingIntegration({ router }),
        ],

        beforeSend(event, hint) {
          const error = hint.originalException;
          if (error && error.message) {
            for (let msg of IGNORE_MESSAGES) {
              if (error.message.match(msg)) {
                return null
              }
            }
          }
          return event;
        },
      });

      router.beforeEach((to, from, next) => {
        Sentry.addBreadcrumb({
          category: 'router',
          message: `Navigate from ${from.path} to ${to.path}`,
          level: 'info',
        });
        next();
      });
    }
  },
};

export default sentry;
