<template>
  <div
    v-if="isVisible"
    :id="id"
    :class="`y-alert ${modeClass}`"
    :style="`color: ${textColor}`"
    :data-cy="cyTarget"
  >
    <div class="y-alert__inner">
      <button
        v-if="isDismissible"
        class="y-alert__close"
        @click="close()"
      >
        <span class="y-alert__close-text">
          {{ $t('yAlert.close') }}
        </span>
        <YIcon name="icon-close" />
      </button>

      <header
        v-if="title || $slots.title"
        class="y-alert__header"
      >
        <div class="y-alert__icon">
          <YIcon :name="`icon-${mode}`" />
        </div>
        <div
          v-if="title"
          class="y-alert__title"
          data-cy="y-alert__title"
        >
          {{ title }}
        </div>
      </header>

      <div
        v-if="text || $slots.text"
        :class="title ? 'y-alert__body' : `y-alert__text__body`"
      >
        <div
          v-if="!title"
          class="y-alert__text__icon "
        >
          <YIcon :name="`icon-${mode}`" />
        </div>
        <div
          v-if="text"
          class="y-alert__text"
          v-html="text"
        />
        <slot
          v-if="$slots.text"
          class="y-alert__text__content"
          name="text"
        />
      </div>
      <footer
        v-if="$slots.primaryAction || $slots.secondaryAction"
        class="y-alert__footer"
      >
        <ul class="y-alert__list">
          <li
            v-if="$slots.secondaryAction"
            class="y-alert__list-item y-alert__list-item--secondary"
          >
            <slot name="secondaryAction" />
          </li>
          <li
            v-if="$slots.primaryAction"
            class="y-alert__list-item y-alert__list-item--primary"
          >
            <slot name="primaryAction" />
          </li>
        </ul>
      </footer>
    </div>
  </div>
</template>

<script>
import YIcon from "./YIcon.vue";

export default {
  name: "YAlert",
  components: {
    YIcon,
  },
  props: {
    cyTarget: {
      type: String,
      default: "y-alert",
    },
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "info",
    },
    isDismissible: {
      type: Boolean,
      default: true,
    },
    autoDismiss: {
      type: Boolean,
      default: false,
    },
    autoDismissTime: {
      type: Number,
      default: 7000,
    },
    textColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
  computed: {
    modeClass() {
      if (!this.mode) {
        return;
      }

      return `y-alert--${this.mode}`;
    },
  },
  mounted() {
    if (this.autoDismiss === true) {
      this.dismissAlertAfterTimeout();
    }
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
      this.$emit("toggle");
    },
    close() {
      this.isVisible = false;
      this.$emit("close", this.id);
    },
    open() {
      this.isVisible = true;
      this.$emit("open");
    },
    dismissAlertAfterTimeout() {
      if (this.autoDismissTime > 0) {
        setTimeout(() => {
          this.$emit("close", this.id);
        }, this.autoDismissTime);
      }
    },
  },
};
</script>

<style lang="scss">
.y-alert {
  pointer-events: initial;
  position: relative;
  -webkit-font-smoothing: antialiased;
  border-top: 4px solid var(--color-active-theme);
  color: var(--color-text);
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  background-color: var(--color-white);
  transition: all 0.2s ease-in-out;

  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  .y-alert-list & {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }
}

.y-alert__close-text {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.y-alert__header {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 3rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.y-alert__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin-left: auto;
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  overflow: hidden;
  transition: color 0.2s ease-in-out;
  color: var(--color-active-theme);
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: var(--color-text);

  .y-icon {
    display: block;
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-primary);
    }
  }
}

.y-alert__icon {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  transition: color 0.2s ease-in-out;
  color: var(--color-active-theme);
  margin-top: 3px;
}

.y-alert__title {
  font-weight: 500;
  margin-bottom: -3px;
  line-height: var(--line-height);
}

.y-alert__text {
  font-weight: 400;
  margin: 0;
}

.y-alert__text__content {
  flex-wrap: wrap
}

.y-alert__text__icon {
  width: 1rem;
  height: 1rem;
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  transition: color 0.2s ease-in-out;
  color: var(--color-active-theme);
  flex-shrink: 0;
}

.y-alert__text__body {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.y-alert__body {
  padding-left: 3rem;
  padding-right: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.y-alert__footer {
  margin-top: -0.5rem;
  padding-left: 3rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 14px;
}

.y-alert__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1rem;
}

.y-alert__list-item {
  min-width: 0;
}

.y-alert__action {
  appearance: none;
  border: none;
  background-color: transparent;
  color: inherit;
  padding: 0;
}

/* Modifiers */
.y-alert--info {
  --color-active-theme: var(--color-status-info);
  --color-active-theme-background: var(--color-status-info-lightest);
    @media (hover: hover) {
      &:hover {
        background-color: var(--color-active-theme-background);
        }
    }
  }

.y-alert--success {
  --color-active-theme: var(--color-status-success);
  --color-active-theme-background: var(--color-status-success-lightest);
  @media (hover: hover) {
    &:hover {
      background-color: var(--color-active-theme-background);
      }
    }
  }

.y-alert--warning {
  --color-active-theme: var(--color-status-warning);
  --color-active-theme-background: var(--color-status-warning-lightest);
  @media (hover: hover) {
    &:hover {
      background-color: var(--color-active-theme-background);
      }
    }
  }

.y-alert--danger {
  --color-active-theme: var(--color-status-danger);
  --color-active-theme-background: var(--color-status-danger-lightest);
  @media (hover: hover) {
    &:hover {
      background-color: var(--color-active-theme-background);
      }
    }
  }
</style>
