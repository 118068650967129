<script setup>
import { computed } from 'vue';
import store from '@/stores';
import YTasksListItem from '@s/YTasks/YTasksListItem/YTasksListItem.vue';

const pendingTasks = computed(() => {
  return store.state.tasks.pending;
});

const activeTask = computed(() => {
  return store.getters['tasks/getActiveTask'];
});

</script>

<template>
  <ul
    class="ÿ-w-full ÿ-flex ÿ-flex-col ÿ-m-0 ÿ-p-0 ÿ-list-none ÿ-gap-2 ÿ-h-80 ÿ-overflow-y-auto"
  >
    <li v-if="activeTask">
      <YTasksListItem
        :item="activeTask"
        active
      />
    </li>
    <li
      v-for="item in pendingTasks"
      :key="item.id"
    >
      <YTasksListItem :item="item" />
    </li>
  </ul>
</template>
