const defaultState = {
  sidebar: true,
  loadingJobs: [],
  activeDrawer: '',
};

const getters = {
  getActiveDrawer: state => state.activeDrawer,
};

const mutations = {

  SET_ACTIVE_DRAWER(state, value) {
    state.activeDrawer = value;
  },

  UNSET_ACTIVE_DRAWER(state) {
    state.activeDrawer = null;
  },

  SET_SIDEBAR(state, value) {
    state.sidebar = value;
  },

  ADD_LOADING_KEY(state, payload) {
    state.loadingJobs.push({
      id: payload.key,
      totalIncrements: payload.total,
      completedIncrements: 0,
    })
  },
  PROGRESS_LOADING_KEY(state, key) {
    let matchLoadingJobIndex = state.loadingJobs.findIndex(job => job.id === key)
    state.loadingJobs[matchLoadingJobIndex].completedIncrements += 1;
    if (state.loadingJobs[matchLoadingJobIndex].completedIncrements >= state.loadingJobs[matchLoadingJobIndex].totalIncrements) {
      state.loadingJobs.splice(matchLoadingJobIndex, 1)
    }
  },
};

const actions = {
  setActiveDrawer({ commit }, value) {
    commit('SET_ACTIVE_DRAWER', value);
  },
  unsetActiveDrawer({ commit }) {
    commit('UNSET_ACTIVE_DRAWER');
  },
  setSidebar({ commit }, value) {
    commit('SET_SIDEBAR', value);
  },
  addLoadingKey({ commit }, payload) {
    commit('ADD_LOADING_KEY', payload);
  },
  progressLoadingKey({ commit }, key) {
    commit('PROGRESS_LOADING_KEY', key);
  },
};

const displayModule = {
  namespaced: true,
  state: defaultState,
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default displayModule;
