let distributorRoutes = [
  {
    path: "/distributor/quotes",
    component: () => import('@c/Supplier/Request'),
    name: "Distributor Requests",
  },
  {
    path: "/distributor/quotes/history",
    component: () => import('@c/Supplier/QuoteHistory'),
    name: "Distributor Quotes",
  },
  {
    path: "/distributor/quotes/products/:gid",
    component: () => import('@c/Supplier/Quote'),
    name: "Product Quotes",
    props: true,
  },
  {
    path: "/distributor/quotes/orders",
    component: () => import('@c/Supplier/Orders'),
    name: "Distributor Orders",
  },
  {
    path: "/distributor/quotes/orders/:gid",
    component: () => import('@c/Supplier/OrdersForGroup'),
    name: "Orders Group",
    props: true,
  },
  {
    path: "/distributor/groups/users",
    component: () => import('@c/shared/users/Users'),
    name: "Distributor Users",
  },
  {
    path: "/distributor/profile",
    component: () => import('@c/Supplier/SupplierProfile'),
    name: "Company information",
  },
  {
    path: "/distributor/productlist",
    component: () => import('@c/Supplier/ProductList'),
    name: "Product List",
  },
  {
    path: "/distributor/deliverylocations",
    component: () => import('@c/Supplier/DeliveryLocationFilters'),
    name: "Delivery Locations",
  },
  {
    path: "/distributor/productfilters",
    component: () => import('@c/Supplier/ProductFilters'),
    name: "Product Filters",
  },
];

export default distributorRoutes;
