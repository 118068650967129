<script>
/**
 * Notification State Mixin
 * Exposes notifications to the components
 */
import NetworkModule from '../../../lib/NetworkModule';

export default {
  data() {
    return {
      notifications_request: new NetworkModule.SingleCancelRequest(),
    };
  },
  methods: {
    getNotifications() {
      let notifications_url = null;
      if (this.isSupplierGroup()) {
        notifications_url = `${this.$store.state.settings.apiLegacyBase}/api/distributor/notifications/${this.group_id}`;
      } else if (this.isFarmGroup()) {
        notifications_url = `${this.$store.state.settings.apiLegacyBase}/api/farm/notifications/${this.group_id}`;
      }

      if (notifications_url) {
        this.notifications_request.get(notifications_url, (parsed_json) => {
          if (parsed_json !== undefined) {
            this.$store.commit('notifications/UPDATE_NOTIFICATIONS', parsed_json);
          }
        });
      }
    },
  },
};
</script>
