export default {
  FEATURE_TYPE: {
    FARMBENCH: "feature.benchmarking.farm",
    SUPPLIERBENCH: "feature.benchmarking.supplier",
    PRICECHECKLITE: "feature.pricecheck.lite",
    PROGRAMMECHECK: "feature.programmecheck",
    EXPLORER: "feature.explorer",
    MARKETWATCH: "feature.marketwatch",
    MARKETPLACE: "feature.marketplace",
    FIELDSANALYTICS: "feature.fields_analytics",
    BUSINESSTRACKER: "feature.bustrack",
    LABOUR_MACHINERY: "feature.labour_machinery",
    INSIGHTS: "feature.insights",
    BREAKEVEN_CALCULATION: "feature.breakeven_calculation",
    DATA_UPLOAD: "feature.data_upload",
    SURVEYS: "feature.surveys",
    END_USE_FILTER: "feature.end_use_filter",
    GROSS_MARGIN_FILTER: "feature.gross_margin_filter",
    ANALYTICS_REPORTS: "feature.analytics_reports",
  },
};
