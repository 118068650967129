let marketwatchRoutes = [
  {
    path: "/marketwatch/commodities",
    component: () => import('@c/Marketwatch/MarketwatchCommodities'),
    name: "Marketwatch Commodities",
    meta: {
      tabTitleKey: "marketwatch.commodities.tabTitle",
      requireGlobalFeature: ["marketwatch"],
    },
  },
  {
    path: "/marketwatch/commodities/:id",
    component: () => import('@c/Marketwatch/Commodity'),
    name: "Marketwatch Commodity",
    meta: {
      tabTitleKey: "marketwatch.commodities.tabTitle",
      requireGlobalFeature: ["marketwatch"],
    },
  },
  {
    path: "/marketwatch/commodities/:id/range/:range",
    component: () => import('@c/Marketwatch/Commodity'),
    name: "MarketwatchCommodity - Range",
    meta: {
      tabTitleKey: "marketwatch.commodities.tabTitle",
      requireGlobalFeature: ["marketwatch"],
    },
  },
  {
    path: "/marketwatch/commodities/:id/future/:future/range/:range",
    component: () => import('@c/Marketwatch/Commodity'),
    name: "Marketwatch Commodity - Future, Range",
    meta: {
      tabTitleKey: "marketwatch.commodities.tabTitle",
      requireGlobalFeature: ["marketwatch"],
    },
  },
  {
    path: "/marketwatch/inputs",
    component: () => import('@c/Marketwatch/MarketwatchInputs'),
    name: "Marketwatch Inputs",
    meta: {
      tabTitleKey: "marketwatch.inputs.tabTitle",
      requireGlobalFeature: ["marketwatch"],
    },
  },
  {
    path: "/marketwatch/alerts",
    component: () => import('@c/Marketwatch/Alerts'),
    name: "Marketwatch Alerts",
    meta: {
      tabTitleKey: "marketwatch.alerts.tabTitle",
      requireGlobalFeature: ["marketwatch"],
    },
  },
  {
    path: "/marketwatch/market-commentary",
    component: () => import('@/pages/admin/MarketCommentary'),
    name: "Marketwatch Market Commentary",
    meta: {
      tabTitleKey: "marketwatch.marketCommentary.tabTitle",
      requireGlobalFeature: ["marketwatch", "trading"],
    },
  },
  {
    path: "/farm/tracker/commodities",
    component: () => import('@c/Marketwatch/MarketwatchCommodities'),
    name: "Tracker Commodities",
    meta: {
      tabTitleKey: "marketwatch.commodities.tabTitle",
      requireGlobalFeature: ["marketwatch"],
    },
  },
  {
    path: "/farm/tracker/commodities/:id",
    component: () => import('@c/Marketwatch/Commodity'),
    name: "Tracker Commodity",
    meta: {
      tabTitleKey: "marketwatch.commodities.tabTitle",
      requireGlobalFeature: ["marketwatch"],
    },
  },
  {
    path: "/farm/tracker/commodities/:id/range/:range",
    component: () => import('@c/Marketwatch/Commodity'),
    name: "Tracker Commodity - Range",
    meta: {
      tabTitleKey: "marketwatch.commodities.tabTitle",
      requireGlobalFeature: ["marketwatch"],
    },
  },
  {
    path: "/farm/tracker/commodities/:id/future/:future/range/:range",
    component: () => import('@c/Marketwatch/Commodity'),
    name: "Tracker Commodity - Future, Range",
    meta: {
      tabTitleKey: "marketwatch.commodities.tabTitle",
      requireGlobalFeature: ["marketwatch"],
    },
  },
  {
    path: "/farm/tracker/inputs",
    component: () => import('@c/Marketwatch/MarketwatchInputs'),
    name: "Tracker Inputs",
    meta: {
      tabTitleKey: "marketwatch.inputs.tabTitle",
      requireGlobalFeature: ["marketwatch"],
    },
  },
  {
    path: "/farm/tracker/alerts",
    component: () => import('@c/Marketwatch/Alerts'),
    name: "Tracker Alerts",
    meta: {
      tabTitleKey: "marketwatch.alerts.tabTitle",
      requireGlobalFeature: ["marketwatch"],
    },
  },
];

export default marketwatchRoutes;
