import store from "@/stores";
import { i18n } from "@/plugins/i18n";
import auth from "@/auth";
import { getBranding } from "@/branding";

export function get_current_language() {
  let lang = store.getters["user/LANGUAGE"];
  if (!lang) {
    let payload = auth.getPayload()
    if (payload) {
      lang = payload['language']
    }
  }

  if (lang) {
    let languages = store.getters["settings/languages"]
    if (languages.includes(lang)) {
      return lang;
    }
  }

  return store.getters["settings/default_language_code"]
}

export async function load_languages() {
  let partner_id = getBranding();
  for (let i = 0; i < store.getters["settings/languages"].length; i++) {
    let lang = store.getters["settings/languages"][i]

    const langModule = await import(`./plugins/${lang}.js`);
    i18n.setLocaleMessage(lang, langModule.default)

    let dateTimePicker = i18n.messages[lang]['dateTimePicker']

    if (partner_id !== "yagro") {
      const partnerModule = await import(`@/plugins/partners/${partner_id}/${lang}.js`);
      i18n.mergeLocaleMessage(lang, partnerModule.default);
    }

    let messages = i18n.messages[lang]
    messages['dateTimePicker'] = dateTimePicker
    i18n.setLocaleMessage(lang, messages)
  }
}
