const notifications_state = {
  open_requests: 0,
  quoted_requests: 0,
  undelivered_requests: 0,
  virtualgroup_invites: 0,
};

/* eslint-disable no-param-reassign */
const notifications_mutations = {
  UPDATE_NOTIFICATIONS(state, notifications) {
    state.open_requests = notifications.open_requests;
    state.quoted_requests = notifications.quoted_requests;
    state.undelivered_requests = notifications.undelivered_requests;
    state.virtualgroup_invites = notifications.virtualgroup_invites;
  },
};

const notifications_actions = {
};

const notifications_getters = {
  get_open_requests(state) {
    return state.open_requests;
  },
  get_quoted_requests(state) {
    return state.quoted_requests;
  },
  get_undelivered_requests(state) {
    return state.undelivered_requests;
  },
  get_virtualgroup_invites(state) {
    return state.virtualgroup_invites;
  },
};

const notifications_module = {
  namespaced: true,
  state: notifications_state,
  getters: notifications_getters,
  mutations: notifications_mutations,
  actions: notifications_actions,
};

export default notifications_module;
