import { nanoid } from 'nanoid'

/**
 * Default State
 */
const defaultState = {
  alerts: [],
  maxAlertsToDisplay: 5,
};

/**
 * Mutations
 */
const alertMutations = {
  ADD_ALERT(state, alert) {
    const id = nanoid();
    state.alerts.push({ id, ...alert });
  },

  REMOVE_ALERT(state, id) {
    const index = state.alerts.findIndex((alert) => alert.id === id);
    if (index !== -1) {
      state.alerts.splice(index, 1);
    } else {
      console.warn(`y-alerts: Could not find alert with id ${id}.`);
    }
  },

  REMOVE_ALL_ALERTS(state) {
    state.alerts = [];
  },
};

/**
 * Actions
 */
const alertActions = {
  requestToAddAlert({ commit }, alert) {
    commit("ADD_ALERT", alert);
  },

  requestToRemoveAlert({ commit }, id) {
    commit("REMOVE_ALERT", id);
  },

  requestToRemoveAllAlerts({ commit }) {
    commit("REMOVE_ALL_ALERTS");
  },
};

/**
 * Getters
 */
const alertGetters = {
  amountOfAlerts: (state) => state.alerts.length,
  paginationRequired: (state) => state.alerts.length > state.maxAlertsToDisplay,
  maxAlertsToDisplay: (state) => state.maxAlertsToDisplay,
};

/**
 * Setup
 */
const StoreAlertsModule = {
  namespaced: true,
  state: defaultState,
  mutations: alertMutations,
  actions: alertActions,
  getters: alertGetters,
};

export default StoreAlertsModule;
