export default {
  install(Vue, options) {
    Vue.mixin({
      computed: {
        selectedYear: {
          set(year) {
            this.$store.commit("selections/SET_YEAR", year);
          },
          get() {
            return this.$store.state.selections.year;
          },
        },

        selected_admin_group_field:{

          set(admin_group){
            this.$store.commit("selections/SET_ADMIN_GROUP_FIELD", admin_group)
          },
          get(){
            return this.$store.state.selections.admin_group_field
          },
        },

        selected_season: {
          set(season) {
            this.$store.commit("selections/SET_SEASON", season);
          },
          get() {
            return this.$store.state.selections.season;
          },
        },

        selected_v_group: {
          set(v_group) {
            this.$store.commit("selections/SET_V_GROUP", v_group);
          },
          get() {
            return this.$store.state.selections.v_group;
          },
        },

        selected_group_field: {
          set(group_field) {
            this.$store.commit("selections/SET_GROUP_FIELD", group_field);
          },
          get() {
            return this.$store.state.selections.group_field;
          },
        },

        selectedCrop: {
          set(crop) {
            this.$store.commit("selections/SET_CROP", {id: crop.id ? Number(crop.id) : null, name: crop.name ? String(crop.name) : null});
          },
          get() {
            return this.$store.state.selections.crop;
          },
        },
      },
    });
  },
};
