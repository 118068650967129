<template>
  <div class="y-alert-list">
    <div class="y-alert-list__inner">
      <div class="y-alert-list__body">
        <YAlertControls
          v-if="paginationRequired"
          :current-page="currentPage"
          :total-pages="totalPages"
          @prevPage="prevPage"
          @nextPage="nextPage"
          @clearAllAlerts="clearAllAlerts"
        />
        <TransitionGroup
          name="list"
          tag="ul"
          class="y-alert-list__list"
        >
          <li
            v-for="alert in paginatedAlerts"
            :key="alert.id"
            class="y-alert-list__list-item"
          >
            <YAlert
              :id="alert.id"
              :title="alert.title"
              :text="alert.text"
              :mode="alert.mode"
              :is-dismissible="alert.isDismissible"
              :auto-dismiss="alert.autoDismiss"
              @close="removeAlert"
            />
          </li>
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import YAlert from "@s/YAlert";
import YAlertControls from "@s/YAlertControls/YAlertControls";

export default {
  components: {
    YAlert,
    YAlertControls,
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    ...mapState('alerts', { alerts: (state) => state.alerts }),
    ...mapGetters('alerts', ['amountOfAlerts', 'paginationRequired', 'maxAlertsToDisplay']),
    paginatedAlerts() {
      const alerts = this.alerts;
      const maxAlertsToDisplay = this.maxAlertsToDisplay;
      const start = (this.currentPage - 1) * maxAlertsToDisplay;
      const end = start + maxAlertsToDisplay;
      return alerts.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.amountOfAlerts / this.maxAlertsToDisplay);
    },
  },
  methods: {
    ...mapActions({
      requestToAddAlert: 'alerts/requestToAddAlert',
      requestToRemoveAlert: 'alerts/requestToRemoveAlert',
      requestToRemoveAllAlerts: 'alerts/requestToRemoveAllAlerts',
    }),
    removeAlert(id) {
      this.requestToRemoveAlert(id);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    clearAllAlerts() {
      this.alerts.forEach((alert) => {
        this.requestToRemoveAllAlerts()
      });
    },
  },
};
</script>

<style lang="scss">
.y-alert-list {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  pointer-events: none;
}

.y-alert-list__inner {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  @media (min-width: 768px) {
    padding: 2rem;
  }
}

.y-alert-list__body {
  width: 100%;
  @media (min-width: 768px) {
    width: 385px;
  }
}

.y-alert-list__list {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.y-alert-list__list-item {
  width: 100%;
}

.y-alert-list__list-item + .y-alert-list__list-item {
  margin-top: 1rem;
}

</style>
