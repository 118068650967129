function isPathAllowed(to, checkPath) {
  let okPaths = [
    "/users/login",
    "/public/positions",
    "/session/user/reset-password",
    "/session/user/invitation",
    "/session/user/group-invite",
    checkPath,
  ];

  for (let path of okPaths) {
    if (to.path.startsWith(path)) {
      return true;
    }
  }
  return false;
}

function isEnabledFeature(feature, features) {
  for (let f of feature) {
    if (!(f in (features ?? {}))) {
      return false;
    }
  }
  return true;
}

export { isPathAllowed, isEnabledFeature }
