import store from "@/stores";
import gql from "graphql-tag";

export function getDocToDownload(apollo, doc_id, version) {
  let query = gql`query Query($documentId: ID, $version: String) {
    getDocToDownload(documentId: $documentId, version: $version) {
      url
      filename
    }
  }`;

  return apollo.query({
    query: query,
    variables: { "documentId": doc_id, "version": version },
    context: {
      headers: store.state.user.group.uuid ? { "X-Yagro-Group-UUID": store.state.user.group.uuid } : {},
    },
  })
    .then((response) => {
      if (response.errors) {
        let error = response.errors[0].message;
        return Promise.reject(error);
      }
      if (!response.data || !response.data.getDocToDownload) {
        let error = "Invalid response data from getDocToDownload.";
        return Promise.reject(error);
      }
      return Promise.resolve(response.data.getDocToDownload);
    })
}

export function download(apollo, doc_id, version) {
  return getDocToDownload(apollo, doc_id, version)
    .then(docInfo => {
      console.log("docInfo:", docInfo);
      if (!docInfo || !docInfo.url || !docInfo.filename) {
        throw new Error("Document or file information is missing");
      }

      // Swapped to using fetch to avoid clashes with Axios Auth headers
      return fetch(docInfo.url, {
        method: "GET",
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then(blob => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", docInfo.filename);
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        })
        .catch(error => {
          console.error("Error fetching the document:", error);
        });
    })
    .catch(error => {
      console.error("Error in getDocToDownload:", error);
      return Promise.reject(error);
    });
}

export function downloadIngestionDoc(apollo, docId) {
  let query = gql`query Query($documentId: ID) {
  readIngestionDocument(documentId: $documentId) {
      documentId
      columns
      jsonFile
      percentageOfDerivedValuesInColumns {
        column
        percentage
      }
    }
  }`
  let result = apollo.query({
    query: query, variables: { "documentId": docId },
  }).then((response) => {
    if (response.errors) {
      let error = response.errors[0].message;
      return Promise.reject(error);
    }
    return Promise.resolve(response.data.readIngestionDocument);
  });

  return result;
}
