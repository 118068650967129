export default {
  state: {},

  getters: {
    apiCache: (state) => (ep) => state[ep],
  },

  mutations: {
    cache(state, {endpoint, value}) {
      state[endpoint] = value;
    },
  },
};
