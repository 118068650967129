let partnerRoutes = [
  {
    path: "/partner/groups",
    component: () => import('@c/Partners/PartnerGroupsList'),
    name: "Managed Farms",
  },
  {
    path: "/partner/supply/contracts",
    component: () => import('@c/Partners/PartnerSupplyContracts'),
    name: "Supply Contracts",
  },
  {
    path: "/partner/supply/contracts/:contractId/data-tracker",
    component: () => import('../pages/partner/DataTrackerDashboard.vue'),
    name: "Data Tracker Dashboard",
  },
  {
    path: "/partner/supply/contracts/:contractId/emissions/:year",
    component: () => import('../pages/partner/EmissionsDashboard.vue'),
    name: "Emissions Dashboard",
  },
];

export default partnerRoutes;
