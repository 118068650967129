<!--
-------------------------------------------------------------------------------
Counter
-------------------------------------------------------------------------------
A counter component, used to display a number in a circle with a
background color.
-------------------------------------------------------------------------------
-->
<script setup>
import { computed } from 'vue'

const props = defineProps({
  number: {
    type: Number,
    default: 0,
    required: true,
  },
  background: {
    type: String,
    default: 'gray',
  },
})

const computedNumber = computed(() => {
  if (props.number > 99) {
    return '99+'
  }
  return props.number
})

const backgroundClass = computed(() => {
  if (props.background === 'green') {
    return 'ÿ-bg-yagro-greenLight'
  } else if (props.background === 'orange') {
    return 'ÿ-bg-[#FFAB00]'
  } else {
    return 'ÿ-bg-neutral-500'
  }
})
</script>

<template>
  <div
    class="ÿ-pointer-events-none ÿ-rounded-100 ÿ-flex ÿ-w-5 ÿ-h-5 ÿ-items-center ÿ-justify-center ÿ-leading-none ÿ-font-semibold ÿ-text-white ÿ-font-size-2.5"
    :class="backgroundClass"
  >
    <span class="ÿ-block ÿ-truncate ÿ-leading-none">{{ computedNumber }}</span>
  </div>
</template>
