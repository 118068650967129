
const getApiUrlBase = function() {
  let apiServer;
  if (window.location.hostname === "localhost") {
    apiServer = "http://localhost:9001"
  } else {
    apiServer = `https://${window.location.hostname}`
  }

  return apiServer;
}

const settings_state = {
  backend_info: undefined,
  env_info: undefined,
  apiBase: getApiUrlBase(),
  // Having this, means we can change all the legacy API's in one place
  apiLegacyBase: getApiUrlBase() + "/legacy",
  graphqlBase: import.meta.env.MODE === 'development' ? "http://localhost:8002" : "",
};

const settings_mutations = {
  SET_BACKEND_SETTINGS(state, backend_info) {
    state.backend_info = backend_info;
  },
  SET_ENV_INFO(state, env_info) {
    state.env_info = env_info;
  },
};

const settings_actions = {
  fetchSettings({ commit }) {

    return new Promise((resolve, reject) => {

      Promise.all([fetch(`${settings_state.apiLegacyBase}/api/shared/settings/site`)
        .then(response => response.json())
        .then(parsed_site_settings => {
          commit("SET_BACKEND_SETTINGS", parsed_site_settings.site);
        })
        .catch(error => {
          commit("SET_BACKEND_SETTINGS", {});
        }),
      fetch("/gui/info/env.json").then(response => response.json())
        .then(parsed_settings => {
          commit("SET_ENV_INFO", parsed_settings);
        })
        .catch(error => {
          commit("SET_ENV_INFO", {
            "languages": [
              "en-gb",
            ],
            "name": "Unknown",
          });
        }),
      ]).then((values) => {
        resolve();
      });


    })
  },
};

const settings_getters = {
  site(state) {
    if (state.env_info !== undefined) {
      return state.env_info.name;
    }
    return "";
  },

  languages: (state) => (state.env_info ?? {}).languages ?? ["en-gb"],

  env_name(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.name;
    }
    return undefined;
  },
  currency_symbol(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.currency.symbol;
    }
    return undefined;
  },

  currency_code(state) {
    return state.backend_info ? state.backend_info.currency.code : undefined;
  },

  default_language_code(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.default_language_code;
    }
    return undefined;
  },
  features(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.features;
    }
    return undefined;
  },
  price_check(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.features.pricecheck;
    }
    return undefined;
  },
  program_check(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.features.programcheck;
    }
    return undefined;
  },
  explorer(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.features.explorer;
    }
    return undefined;
  },
  savings(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.features.savings;
    }
    return undefined;
  },
  marketplace(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.features.marketplace;
    }
    return undefined;
  },
  marketwatch(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.features.marketwatch;
    }
    return undefined;
  },
  labour_machinery(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.features.labour_machinery;
    }
    return undefined
  },
  business_tracker(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.features.business_tracker;
    }
    return undefined;
  },
  request_products(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.features.request_products;
    }
    return undefined;
  },
  group_delivery_locations(state) {
    if (state.backend_info !== undefined) {
      return state.backend_info.features.group_delivery_locations;
    }

    return undefined;
  },
  prototype(state) {
    return (state.backend_info !== undefined) ? state.backend_info.features.prototype : undefined
  },

  getApiUrlBase(state) {
    return state.apiBase;
  },
};

const settings_module = {
  namespaced: true,
  state: settings_state,
  getters: settings_getters,
  mutations: settings_mutations,
  actions: settings_actions,
};

export default settings_module;
