<!--
/*
-------------------------------------------------------------------------------
YIcon
-------------------------------------------------------------------------------
Description
This component is used to display an icon from the assets/svg folder inline.

Note: This version of the component is quite different from the version used
in yagro-demo. This is due to large differences in the build system and
legacy dependencies.

Reference
https://github.com/damianstasik/vue-svg-loader/issues/51#issuecomment-431019099
-------------------------------------------------------------------------------
*/
-->

<template>
  <div
    class="y-icon"
    :class="classname"
  >
    <component
      :is="currentIcon"
      class="svg"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  props: {
    name: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    currentIcon() {
      /* Uses a relative path, as vite-svg-loader doesn't seem to work with aliases */
      return defineAsyncComponent(() => import(`../../assets/icons/${this.name}.svg?component`))
    },
    classname() {
      const name = this.name.replace("icon-", '');
      return `y-icon--${name}`
    },
  },
};
</script>

<style>
.y-icon {
  display: block;

  .svg {
    display: block;
  }
}
</style>
